<template>
  <navbarComp :navStyleData="navStyleData" ref="navbar"></navbarComp>
  <main :style="{marginTop : navStyleData.height + 'vw'}" ref="main">
      <div class="poster">
        <section>
          <h1>Olga Biodiversity</h1>
          <p>Search species at {{ activeAirport.name }} !</p>
          <div class="search-bar">
            <img src="@/assets/home/search.png">
            <input placeholder="Search a species..." type="text"/>
            <button>Search</button>
          </div>
        </section>
        <img class="img-background" :src="activeAirport.image">
      </div>

      <div class="poster right">
        <section>
          <h1>Interactive Map</h1>
          <p>{{ activeAirport.name }} Efforts to protect Biodiversity</p>
          <router-link class="solo" :to="'/map/' + $store.state.activeAirportKey">Visit</router-link>
        </section>
        <img class="img-background" src="@/assets/home/map.png">
      </div>
  </main>

  <footerComp :marginTop="4"></footerComp>
</template>

<script>
import navbarComp from '@/components/navbarComp.vue'
import footerComp from '@/components/footerComp.vue'

export default {
  data(){
    return{
      navStyleData : { height : 0 }
    }
  },
  components : {
    navbarComp,
    footerComp,

  },

  computed : {
    activeAirport(){
      return this.$store.state.activeAirport
    }
  },
  mounted(){
    this.$refs.main.style.marginTop = this.$refs.navbar.getHeight()  + 'vw' 
  },

}
</script>

<style scoped>
  main{
    width: 100%;
  }

  .poster{
    height: 30vw;
    width: 100%;
    position: relative;
    background: linear-gradient(90deg, var(--green) 0%, rgba(255, 255, 255, 0) 100%); 
    margin-bottom: 3vw;
  }

  /* INVERSE LE GRADIENT */
  /* .poster.right{
    background: linear-gradient(-90deg, var(--green) 0%, rgba(255, 255, 255, 0) 100%); 
  } */

  .poster section{
    width: 60%;
    height: 100%;
    padding: 5vw;
  }

  .poster.right section{
    margin-left: auto;
  }

  .poster section h1{
    font-size: 4vw;
    color: white;
    margin-bottom: 1vw;
  }
  .poster section p{
    width: 80%;
    color: white;
    font-size: 1.5vw;
  }
  .poster section .solo{
    display: block;
    text-align: center;
    text-decoration: none;
    background-color: white;
    width: 90%;
    font-size: 1vw;
    color: var(--green);
    cursor: pointer;
    border: none;
    padding: 1vw;
    margin-top: 3vw;
    transition: 0.2s ease;
  }
  .poster section .solo:hover{
    color: white;
    background-color: var(--green);
  }
  .poster .img-background{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    object-fit: cover;
  }

  .search-bar{
    margin-top: 4vw;
    width: 80%;
  }

</style>
