<template>
    <span></span>
</template>

<script>
export default {
    mounted() {
        this.init()
    },
    methods: {
        init() {
            //Verifier que l’aéroport existe sinon le renvoi a l'accueil (Premier aéroports)
            const activeAirportParam = this.$route.params.airport;
            const airportList = Object.keys(this.$store.state.airports);
            if (airportList.includes(activeAirportParam)) {
                this.$store.commit("setActiveAirport", activeAirportParam);
            }
            else {
                this.$router.push("/home/" + airportList[0]);
            }
        },
    },
    watch: {
        $route() {
            this.init()
        }
    },
}
</script>

<style scoped>
span {
    display: none;
}
</style>