<template>
  <div class="wrapper">
    <!-- ? Title -->
    <h4 id="field-title" :class="{ 'visible': isHovered }">{{ title }}</h4>

    <!-- ? Form Element -->
    <!-- Standard View -->
    <div v-show="!model.hidden_showOption" @mouseover="isHovered = true" @mouseleave="isHovered = false"
      class="form-zone-wrapper__form-field">

      <!-- * Form Element Content -->
      <slot name="content" />

      <!-- * From Element Drag Handle -->
      <div class="form-field__field-top-right-group">
        <DragHandle>
          <div class="field-top-right__form-field-drag-handle">
            <img src="@/assets/image/forms/form-skeleton-drag-icon.svg" alt="Drag">
          </div>
        </DragHandle>

        <!-- * Form Element Options -->
        <!-- if no options, display delete button -->
        <div v-if="noOptions" class="field-top-right__form-field-options"
          :class="{ '--delete-option': isDeletionTriggered, '--transition': isDeletionTriggered }"
          @click="isDeletionTriggered ? $emit('callToDeleteSelf') : isDeletionTriggered = true">
          <img src="@/assets/image/forms/form-field-trash-bin-icon.svg" alt="🗑️">
        </div>
        <div v-else class="field-top-right__form-field-options"
          @click="model.hidden_showOption = !model.hidden_showOption; isDeletionTriggered = false">
          <img src="@/assets/image/forms/form-skeleton-option-icon.svg" alt="Parameter">
        </div>

      </div>

    </div>
    <!-- Parameter View -->
    <div v-show="model.hidden_showOption" class="form-zone-wrapper__form-field"
      :class="{ '--parameter-view': model.hidden_showOption }">
      <div class="form-field__parameter-group">
        <!-- * Options -->
        <slot name="options">
          <!-- Default content -->
          <div class="parameter-group__default-parameter-content">
            <img src="@/assets/image/forms/form-skeleton-warning-icon.svg" alt="<!>">
            <h3>No option is available for this field.</h3>
          </div>
        </slot>
        <div class="parameter-group__delete-group">
          <!-- Delete button -->
          <button @click="isDeletionTriggered ? $emit('callToDeleteSelf') : isDeletionTriggered = true"
            :class="{ 'delete-confirmation': isDeletionTriggered }">
            <img src="@/assets/image/forms/form-field-trash-bin-icon.svg" alt="🗑️">
          </button>
        </div>
      </div>

      <!-- * Mandatory Buttons -->
      <!-- Drag & Parameter buttons -->
      <div class="form-field__field-top-right-group">

        <DragHandle>
          <div class="field-top-right__form-field-drag-handle">
            <img src="@/assets/image/forms/form-skeleton-drag-icon.svg" alt="Drag">
          </div>
        </DragHandle>

        <div class="field-top-right__form-field-options" @click="model.hidden_showOption = !model.hidden_showOption">
          <img src="@/assets/image/forms/form-skeleton-option-icon.svg" alt="Parameter">
        </div>
      </div>
    </div>
  </div>
</template>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<script>
export default {
  data() {
    return {
      noOptions: false,
      isHovered: false,
    };
  },
  mounted() {
    this.noOptions = !this.$slots.options;
  },
}
</script>

<script setup>
import { defineEmits, defineModel, defineProps, ref, watch } from 'vue';
import { DragHandle } from 'vue-slicksort';

const model = defineModel();
let isDeletionTriggered = ref(false);

defineProps({
  title: String,
});

defineEmits(['callToDeleteSelf']);

watch(isDeletionTriggered, (newValue) => {
  if (newValue) {
    setTimeout(() => {
      isDeletionTriggered.value = false;
    }, 4000);
  }
});

</script>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<style scoped lang="scss">
h4 {
  max-height: 0;
  padding: 0;
  margin: 0;
  opacity: 0;
  visibility: hidden;

  &.visible {
    position: relative;
    visibility: visible;
    opacity: 1;
    top: -1.2vw;
  }
}

.form-zone-wrapper__form-field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: var(--grey-light);
  border: 0.1vw solid var(--grey);
  border-radius: var(--small-radius);

  padding: 0.5vw;
  gap: 0.5vw;

  &.--parameter-view {
    background-color: var(--grey);
    border: 0.1vw solid var(--grey-dark);
  }

  &:hover {
    outline: 0.1vw solid var(--grey-darker);
  }

  .form-field__field-top-right-group {
    background-color: var(--grey-dark);
    border-radius: var(--small-radius);

    outline: 0.1vw solid var(--grey-darker);

    .field-top-right__form-field-drag-handle:hover,
    .field-top-right__form-field-options:hover {
      background-color: var(--grey-light);
      transition: background-color 0.3s;
    }

    .field-top-right__form-field-drag-handle {
      display: flex;
      flex-direction: row;
      justify-content: center;

      border-bottom: 0.1vw solid var(--grey-darker);

      img {
        width: 2.63vw;
        filter: invert(15%) sepia(0%) saturate(502%) hue-rotate(165deg) brightness(97%) contrast(84%);
        cursor: move;
      }
    }

    .field-top-right__form-field-options,
    .field-top-right__field-delete-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      cursor: pointer;

      img {
        width: 2.63vw;
        pointer-events: none;
      }
    }
  }

  .form-field__parameter-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: 0.5vw;
    gap: 0.5vw;
  }

  .parameter-group__default-parameter-content {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;

    gap: 0.3vw;
    height: 100%;

    background-color: var(--grey-dark);
    border: 0.1vw solid var(--grey-darker);
    border-radius: var(--small-radius);

    h3 {
      font-size: 1.2vw;
    }

    img {
      pointer-events: none;
      display: flex;
      flex-direction: row;
      justify-content: center;

      width: 1.5vw;
    }

    /* background-color: var(--grey-light); */
    /* border: 0.1vw solid var(--grey); */
    /* border-radius: var(--small-radius); */
  }

  .parameter-group__delete-group {
    button {
      background-color: var(--grey-light);
      border-radius: var(--small-radius);
      border: 0.01vw solid var(--grey-darker);

      padding: 0.2vw;
      font-size: 0.4vw;
      cursor: pointer;

      img {
        pointer-events: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 1.5vw;
      }
    }
  }

  .parameter-group__delete-group button.delete-confirmation,
  .field-top-right__form-field-options.--delete-option {
    background-color: #ff4d4d;

    img {
      filter: invert(100%) sepia(0%) saturate(502%) hue-rotate(0deg) brightness(100%) contrast(100%);
    }

    &.--transition img {
      filter: none;
      animation: flash 4s linear;
    }
  }

  .parameter-group__delete-group button.delete-confirmation {
    border: 0.01vw solid #ff1a1a;
  }
}

@keyframes flash {

  0%,
  50%,
  100% {
    background-color: #ff6363;
  }

  25%,
  75% {
    background-color: #ff9191;
  }
}
</style>