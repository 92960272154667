<template>
    <div :style="{ width: monthWidth * nbMonths + 'vw' }" class="action">
        <span class="line" :style="{backgroundColor: getColor(false),  width: getMarginLeft(data) + 'vw' }"></span>
        <span class="content" :style="{backgroundColor: getColor(true) , marginLeft: getMarginLeft(data) + 'vw', width: getWidth(data) + 'vw' }">
            {{ data.title }}</span>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
        monthWidth: Number,
        parentData : Object,
        nbMonths : Number
    },

    data(){
        return {
            actualColor : 0,
            colors : ["#3232ff"]
        }
    },

    methods: {
        getMonthsDifference(startYear, startMonth, endYear, endMonth) {
            let months = 0
            for (let y = startYear; y <= endYear; y++) {
                let startMonthRound = startMonth
                if (y > startYear) startMonthRound = 0
                let endMonthRound = 12
                if (y == endYear) endMonthRound = endMonth
                for (let m = startMonthRound; m < endMonthRound; m++) {
                    months += 1
                }
            }
            return months
        },
        getMarginLeft(data) {
            const monthsDifference = this.getMonthsDifference(this.parentData.startYear, this.parentData.startMonth, data.startYear, data.startMonth)
            const numberOdDaysInStartMonth = this.getNumberOfDaysInMonth(data.startYear, data.startMonth)
            return monthsDifference * this.$props.monthWidth + ((data.startDate - 1) * this.$props.monthWidth / numberOdDaysInStartMonth)
        },
        getWidth(data) {
            const monthsDifference = this.getMonthsDifference(data.startYear, data.startMonth, data.endYear, data.endMonth)
            const numberOdDaysInStartMonth = this.getNumberOfDaysInMonth(data.startYear, data.startMonth)
            const numberOdDaysInEndMonth = this.getNumberOfDaysInMonth(data.endYear, data.endMonth)
            return monthsDifference * this.$props.monthWidth -
                ((data.startDate - 1) * this.$props.monthWidth / numberOdDaysInStartMonth)
                + ((data.endDate - 1) * this.$props.monthWidth / numberOdDaysInEndMonth)
        },
        getNumberOfDaysInMonth(year, month) {
            // 1 pour janvier
            return new Date(year, month, 0).getDate()
        },
        getColor(add){
            add
            return this.colors [this.actualColor]
        }
    }
}
</script>

<style scoped>
.action{
  width: 100%;
  padding: 0.5vw 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


.action .content{
  font-family: "Century Gothic";
  display: flex;
  align-items: center;
  color: white;
  font-size: 0.8vw;
  justify-content: center;
  height: 2.5vw;
  border-radius: 2vw;
  background-color: #3232ff;
}
.action .line{
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 0.01vw;
  background-color: rgb(50, 50, 255);
}
</style>