<template>
    <skeletonField v-model="model" @callToDeleteSelf="deleteSelf(index)" :title="title">
        <template v-slot:content>
            <div class="form-field__form-field-content">

                <!-- Field key (input for database) -->
                <fieldKeySelect v-model="model.field_key" :keys="$store.state.formKeys" />

                <!-- Datepicker label -->
                <fieldLabelInput v-model="model.field_label" :placeholder="'This datepicker asks the user to...'" />

                <!-- Datepicker -->
                <input type="datetime-local">

            </div>
        </template>
    </skeletonField>
</template>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<script setup>
import { defineModel, defineProps, } from 'vue';
import skeletonField from './skeletonField.vue'
import fieldKeySelect from "@/components/map/topTools/forms/customElements/fieldKeySelect.vue";
import fieldLabelInput from '@/components/map/topTools/forms/customElements/fieldLabelInput.vue';

const model = defineModel();

defineProps({
    title: String,
    index: Number,
    deleteSelf: Function
});
</script>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<style scoped lang="scss">
#field-element:invalid {
    box-shadow: var(--red-glow-shadow);
}

.form-field__form-field-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 89%;
    gap: 0.2vw;
}

input {
    height: 1.8vw;
    width: 50%;
    font-size: 1.2vw;
    padding: 0.3vw;

    &:focus {
        outline: 0.01vw solid var(--grey-darker);
    }
}
</style>