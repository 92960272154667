<template>
    <skeletonField v-model="model" @callToDeleteSelf="deleteSelf(index)" :title="title">
        <template v-slot:content>

            <div class="form-field__form-field-content">

                <div class="form-field-content__content-header --preset">
                    <genericSelect v-model="selectedPreset" :placeholder="'Select a preset...'" :options="[
                        { code: 'useSource', label: 'Use a predefined source' },
                        { code: 'makeOwnSelect', label: 'Make your own select' }
                    ]" isRequired>
                        <template #icon>
                            <img src="@/assets/image/forms/form-select-icon.svg" alt="?">
                        </template>
                    </genericSelect>

                    <!-- Field key (input for database) -->
                    <fieldKeySelect v-model="model.field_key" :keys="$store.state.formKeys" />
                </div>

                <hr v-if="selectedPreset !== ''">

                <!-- ? Make your own select -->
                <div v-if="selectedPreset === 'makeOwnSelect'">

                    <div class="form-field-content__content-header">

                        <!-- Collapse Button -->
                        <button class="content-header__collapse-button" :class="{ collapsed: isElementCollapsed }"
                            @click=" collapseElement()">
                            <img src="@/assets/image/forms/form-header-collapse-icon.webp">
                        </button>

                        <!-- Select Label -->
                        <input type="text" v-model="model.field_label" id="field-element" required
                            placeholder="This select asks the user...">

                    </div>
                    <div class="form-field-content__content">

                        <!-- Select Items -->
                        <SlickList axis="y" v-model:list="model.select_options" class="content__section-items"
                            :class="{ collapsed: isElementCollapsed }" appendTo=".top-tools" useDragHandle>
                            <SlickItem v-for="(option, index) in model.select_options" class="section-items__item"
                                :key="option" :index="index"
                                :class="{ selected: selectedOption.map(option => option.hidden_id).includes(option.hidden_id) }">

                                <!-- Delete Button -->
                                <button @click="removeOptionFromSelect(model, option), checkMax()">
                                    <img src="@/assets/image/forms/form-field-trash-bin-icon.svg"
                                        style="width: 1.5vw; height: 1.5vw;">
                                </button>

                                <!-- Option Label -->
                                <input type="text" v-model="option.label" placeholder="Option label..."
                                    id="field-element" @change="dontAllowExistingOptions(), model.select_source = null"
                                    required>

                                <!-- Drag Handle -->
                                <DragHandle>
                                    <!-- Prevent default button press behavior -->
                                    <div class="item__drag-handle">
                                        <img src="@/assets/image/forms/form-drag-alternative-icon.svg" alt="Drag">
                                    </div>
                                    <!-- ✋ -->
                                </DragHandle>

                                <div class="item__drag-handle --click" @click="handleOptionClick(option)">
                                    <img src="@/assets/image/forms/select-field-click-icon.svg"
                                        style="width: 1.5vw; height: 1.5vw;">
                                </div>

                            </SlickItem>
                        </SlickList>

                    </div>
                    <div class="form-field-content__content-footer">
                        <form class="content-footer__new-option" :class="{ collapsed: isElementCollapsed }"
                            @submit.prevent="addOptionToSelect(model, model.hidden_newOption)">
                            <input type="text" v-model="model.hidden_newOption" placeholder="New choice...">
                            <button type="submit">
                                <img src="@/assets/image/forms/select-field-add-button.svg"
                                    style="width: 1.5vw; height: 1.5vw;">
                            </button>
                        </form>
                    </div>
                </div>

                <!-- ? Use a predefined source -->
                <div v-else-if="selectedPreset === 'useSource'" class="form-field-content__content-header --source">
                    <!-- Sources dropdown -->

                    <!-- Select Label -->
                    <input type="text" v-model="model.field_label" id="field-element" required
                        placeholder="This select asks the user...">

                    <!-- <label for="source">Source : -->
                    <!-- <select v-model="model.select_source" @change="model.select_options = []"> -->
                    <!-- <option v-for="source in sources" :key="source" :value="source.value">{{ source.label }} -->
                    <!-- </option> -->
                    <!-- </select> -->
                    <!-- </label> -->

                    <genericSelect v-model="model.select_source" :placeholder="'Select a source...'" :options="sources"
                        isRequired optionsKey="value" optionsValue="label">
                        <template #icon>
                            <img src="@/assets/image/forms/form-select-icon.svg" alt="?">
                        </template>
                    </genericSelect>
                </div>
            </div>

        </template>
        <template v-slot:options>

            <div class="parameter-group__option">
                <button class="--option">
                    <label>Multi Input</label>
                    <input type="checkbox" v-model="model.select_multi">
                </button>
                <button class="--option">
                    <label>Allow Search</label>
                    <input type="checkbox" v-model="model.select_search">
                </button>
            </div>

            <div class="parameter-group__option">
                <!-- Ability to change min "select_min" & "select_max" answers -->

                <button class="--option">
                    <!-- Min. Answers : -->
                    <label>Min. Answers :</label>
                    <input type="number" v-model="model.select_min" :min="1" :max="model.select_options.length"
                        @change="checkMax()">
                </button>

                <button class="--option">
                    <!-- Max. Answers : -->
                    <label>Max. Answers :</label>
                    <input type="number" v-model="model.select_max" :min="model.select_min"
                        :max="model.select_options.length" @change="checkMax()">
                </button>
            </div>
            <div class="parameter-group__option">
                <button class="--option">
                    <label>Link to :</label>
                    <div>
                        <fieldKeySelect v-model="model.field_link" :keys="$store.state.formKeys" />
                    </div>
                </button>
                <button class="--option">
                    <label>Clear link</label>
                    <button @click="clearFieldLink()">
                        <img src="@/assets/image/forms/form-field-trash-bin-icon.svg"
                            style="width: 1.5vw; height: 1.5vw;">
                    </button>
                </button>
            </div>
            <div class="parameter-group__option">
                <button class="--option">
                    <label>🔑 Required :</label>
                    <input type="checkbox" v-model="model.field_required">
                </button>
            </div>

        </template>
    </skeletonField>
</template>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<script setup>
import skeletonField from './skeletonField.vue'
import genericSelect from "@/components/map/topTools/forms/customElements/commonElements/genericSelect.vue";
import fieldKeySelect from "@/components/map/topTools/forms/customElements/fieldKeySelect.vue";

import {
    SlickList, SlickItem, DragHandle
} from 'vue-slicksort';
import {
    defineModel,
    defineProps,
    ref
} from 'vue';

const model = defineModel()
// FIXME Hardcoded sources in select field
const sources = [
    {
        label: 'Inventory Codes',
        value: 'code_inventory'
    },
    {
        label: 'Species',
        value: 'species'
    },
    {
        label: 'Users Email',
        value: 'users_email'
    }
]

const selectedPreset = ref('');

// FIXME // ! This fixes a crash but should probably be handled elsewhere
if (!model.value.select_options) {
    model.value.select_options = [];
}

if (model.value.select_options && model.value.select_options.length > 0) {
    selectedPreset.value = 'makeOwnSelect';
} else if (model.value.select_source !== null) {
    selectedPreset.value = 'useSource';
}

defineProps({
    title: String,
    index: Number,
    deleteSelf: Function,
});

const isElementCollapsed = ref(false);
let selectedOption = ref([]);

/**
 * Method responsible for adding an option to a select field
 *
 * @param {Object} element - form field element
 * @param {string} option - option to add
 * @version 1.0.3
 */
function addOptionToSelect(element, option) {
    if (option === undefined || option === '' || element.select_options.map(option => option.label).includes(option)) {
        element.hidden_newOption = '';
        return
    } else if (option.trim() === '') {
        element.hidden_newOption = '';
        return
    }
    element.select_options.push(
        {
            label: option,
            hidden_id: Date.now()
        }
    );
    element.hidden_newOption = '';
}
function removeOptionFromSelect(element, option) {
    element.select_options.splice(element.select_options.indexOf(option), 1);
}
function collapseElement() {
    isElementCollapsed.value = !isElementCollapsed.value;
}
function handleOptionClick(option) {
    // Behavior based on select_min & select_max

    // - Single choice
    if (this.model.select_min === 1 && this.model.select_max === 1) {
        selectedOption.value = [option];
        return
    }

    // - Multiple choice
    else {
        // Check if clicking would exceed the max
        if (selectedOption.value.length >= this.model.select_max) {

            // Check if the option is already selected
            if (selectedOption.value.includes(option)) {
                selectedOption.value.splice(selectedOption.value.indexOf(option), 1);
            }
            return

        } else {

            // Check if the option is already selected
            if (selectedOption.value.includes(option)) {
                selectedOption.value.splice(selectedOption.value.indexOf(option), 1);
            } else {
                selectedOption.value.push(option);
            }
        }
    }
}

function checkMax() {
    if (selectedOption.value.length > this.model.select_max) {
        selectedOption.value = selectedOption.value.slice(0, this.model.select_max);
    }
}
function dontAllowExistingOptions() {
    // If the users rename an existing option to another existing option, the input will be cleared and the option will not be renamed
    for (let i = 0; i < this.model.select_options.length; i++) {
        for (let j = 0; j < this.model.select_options.length; j++) {
            if (i !== j && this.model.select_options[i].label === this.model.select_options[j].label) {
                this.model.select_options[j].label = ''
            }
        }
    }
}

function clearFieldLink() {
    this.model.field_link = '';
}

</script>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<style scoped lang="scss">
#field-element:invalid {
    box-shadow: var(--red-glow-shadow);
}

.form-field__form-field-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    gap: 0.5vw;
}

input {
    height: 1.8vw;
    width: 50%;
    font-size: 1.2vw;
    padding: 0.3vw;

    &:focus {
        outline: 0.01vw solid var(--grey-darker);
    }
}

img {
    pointer-events: none;
}

.form-field-content__content-header {
    display: flex;
    align-items: center;

    margin-bottom: 0.2vw;

    input {
        width: 100%;
        background-color: var(--white);
        border: 0.01vw solid var(--grey-darker);
        border-radius: 0 var(--small-radius) var(--small-radius) 0;
        box-shadow: var(--medium-shadow);
    }

    &.--preset {
        display: flex;
        flex-direction: column;

        select {
            width: 100%;
            height: 1.8vw;
            font-size: 1.2vw;
            padding: 0.1vw;

            &:focus {
                outline: 0.01vw solid var(--grey-darker);
            }
        }
    }

    &.--source {
        flex-direction: column;

        select {
            width: 50%;
            height: 1.8vw;
            font-size: 1.2vw;
            padding: 0.1vw;

            &:focus {
                outline: 0.01vw solid var(--grey-darker);
            }
        }
    }
}

.content-header__collapse-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    background-color: var(--grey-dark);
    border: 0.01vw solid var(--grey-darker);
    border-radius: var(--small-radius) 0 0 var(--small-radius);

    height: 1.8vw;

    img {
        height: 1.8vw;

        display: inline-flex;
        justify-content: center;
        align-items: center;

        pointer-events: none;

        transition: transform 0.5s ease-in-out;
        transform: rotate(-90deg);
    }

    &.collapsed img {
        transform: rotate(90deg);
    }
}

.content__section-items {
    display: flex;
    flex-direction: column;

    margin-left: 1vw;
    gap: 0.2vw;

    &.collapsed {
        max-height: 0;
        padding: 0;
        margin: 0;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }
}

.section-items__item {
    display: flex;
    align-items: center;

    height: 1.8vw;

    background-color: var(--grey);
    /* border-radius: 4px; */

    img {
        height: 1.8vw;
        width: 1.8vw;
    }

    &.selected {
        background-color: var(--green);
    }
}

/* .form-field-content__content-footer {} */

.content-footer__new-option {
    display: flex;
    align-items: center;

    margin-top: 0.5vw;

    input {
        width: 100%;
        background-color: var(--grey-light);
        border: 0.01vw solid var(--grey-dark);
        border-radius: var(--small-radius) 0 0 var(--small-radius);
    }

    button {
        height: 1.8vw;
        width: 1.8vw;

        border-radius: 0 var(--small-radius) var(--small-radius) 0;
        background-color: var(--grey-dark);
        border: 0.01vw solid var(--grey-darker);
    }
}

.item__drag-handle {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 3vw;

    &.--click {
        /* align to top right */
        margin-left: auto;
        cursor: pointer;
    }
}

.parameter-group__option {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5vw;

    .--option {
        display: inline-flex;
        flex-direction: row;
        align-items: center;

        background-color: var(--grey-light);
        border-radius: var(--small-radius);
        border: 0.01vw solid var(--grey-darker);

        padding: 0.2vw;
        font-size: 0.9vw;

        label {
            margin-right: 0.2vw;
            /* filter: grayscale(100%); */
        }

        input {
            display: inline-flex;
            flex-direction: row;
            align-items: center;

            width: 3vw;
            height: 1.1vw;
            font-size: 0.9vw;
            padding: 0.2vw;

            &:focus {
                border: none;
                /* outline: 0.01vw solid var(--grey-darker); */
                /* border-radius: var(--small-radius); */
            }
        }

        input[type="checkbox"] {

            width: 1vw;
            height: 1vw;

            border-radius: var(--small-radius);
            border: none;
            outline: none;
        }
    }
}
</style>