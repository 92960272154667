<template>
  <RouterView></RouterView>
</template>
<script>


export default {
  name: 'App',
  mounted() {
  }
}
</script>



<style>
::-webkit-scrollbar {
  width: 0.2vw;
  /* Largeur de la scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #c4c4c461;
  /* Couleur de fond de la poignée de la scrollbar */
}

@import url("@/assets/css/searchbar.css");


@font-face {
  font-family: 'Century Gothic Bold';
  src: url('@/assets/fonts/CenturyGothicBold.ttf');
}

@font-face {
  font-family: 'Century Gothic';
  src: url('@/assets/fonts/CenturyGothic.ttf');
}

/* Global Styles */
:root {
  --white: #ffffff;

  --grey-light: #F8F8F8;
  --grey: #e0e0e0;
  --grey-dark: #d4d4d4;
  --grey-disabled: #a9a9a9;
  --grey-darker: #808080;

  --black-light: #333333;
  --black: #000000;
  --black-dark: #1a1a1a;

  --blue-lighter: #B3D1F3;
  --blue-light: #4A90E2;
  --blue: #007BFF;
  --blue-dark: #0056b3;
  --blue-darker: #004080;

  --green-light: #569899;
  --green: #026566;
  --green-dark: #396566;
  --green-darker: #014344;

  --green-alternative-light: #A5AE9C;
  --green-alternative: #78866B;
  --green-alternative-dark: #6E7468;

  --orange-light: #FF8855;
  --orange: #ff4d00;
  --orange-dark: #AA5A38;
  --orange-darker: #AA3300;

  --red-light: #E17474;
  --red: #D32F2F;
  --red-dark: #964D4D;
  --red-darker: #8C1F1F;

  --small-radius: 0.2vw;
  --medium-radius: 0.5vw;
  --large-radius: 0.6vw;

  --small-shadow: 0.05vw 0.05vw 0.15vw var(--grey-dark);
  --medium-shadow: 0.1vw 0.1vw 0.3vw var(--grey-dark);
  --large-shadow: 0.2vw 0.2vw 0.6vw var(--grey-dark);
  --red-glow-shadow: 0 0 5px 1px rgba(255, 0, 0, 0.1);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Century Gothic Bold', ui-sans-serif;
}
</style>
