<template>
    <div class="input-row">
        <p v-html="content.fullNameHtml"></p>
        <div @mouseenter="mouseInStates = true" @mouseleave="mouseInStates = false" class="states">
            <img v-if="state == 1" src="@/assets/map/loading.gif" />
            <img v-if="state == 2" src="@/assets/map/valid.png" />
            <img v-if="state == 3" src="@/assets/map/error.png" />
            <p v-if="mouseInStates && message != null" id="message">{{message}}</p>
        </div>

    </div>
</template>

<script>
export default 
{
    props : 
    {
        content : String
    },

    data()
    {
        return {
            state : null,
            mouseInStates : false,
            message : "Error not handleddasdasdsdsadasdasdasdas"
        }

    },
    methods  :
    {
        async try()
        {
            this.state = 1            
            var response = await fetch(process.env.VUE_APP_BACKEND_V2_URL + "/addTaxonToDatabase/" + this.$props.content.scientificName, {
                method : "POST"
            })
            var json = await response.json();
            
            if(json.error)
            {
                this.state = 3
                this.message = json.error
            }
            if(json.success)
            {
                this.state = 2
                this.message = json.success
            }
        },
    },
    mounted()
    {
        
    }
    
}

</script>


<style scoped>


.input-row {
    width: 100%;
    padding: 0.7vw;
    background-color: rgba(128, 128, 128, 0.123);
    border-radius: 0.5vw;
    display: flex;
    height: 2.7vw;
    align-items: center;
    justify-content: center;
}

.input-row p {
    background: none;
    font-size: 0.8vw;
    font-family: "Century Gothic";
    border: 0;
    width: 60%;
    word-wrap: break-word;
    border-right: 0.1vw solid rgba(0, 0, 0, 0.164);
}

.states 
{
    gap: 0.5vw;
    display: flex;
    position: relative;
    width: 40%;
    height: 100%;
    justify-content: flex-end;
    padding: 0 0.5vw;
}

.states img
{
    animation: pop 0.3s ease ;
    height: 100%;
    width: auto;
}

#message 
{
    animation: pop 0.1s ease ;
    position: absolute;
    background-color: rgb(255, 255, 255);
    left: 0.5vw;
    top: 0%;
    width: 70%;
    height: auto;
    border: 0.1vw solid black;
    padding: 0.5vw
}


@keyframes pop {
    from  
    {
        scale : 0
    }
    to 
    {
        scale: 1;
    }
}


</style>