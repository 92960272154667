<template>
    <div class="right-tools" :class="{ pulled: selectedTool != null, expanded: expanded && selectedTool != null  }">
        <div class="tool-container">
            <alertToolComp @alertClicked="handleElementClicked" ref="alertTool" v-if="selectedTool == 'alerts'">
            </alertToolComp>
            <actionsToolComp v-if="selectedTool == 'actions'"></actionsToolComp>
            <observationToolComp @addSpecies="$emit('addSpecies')" @setExpand="handleSetExpand"
                @observationClicked="handleElementClicked" ref="observationTool" @addObservation="handleAddObservation"
                v-show="selectedTool == 'observations'"></observationToolComp>
        </div>
        <div ref="toolbar" class="toolbar">
            <button :class="{ selected: selectedTool == 'alerts' }" id="alerts">
                <img src="@/assets/map/alert.png">
            </button>
            <button :class="{ selected: selectedTool == 'actions' }" id="actions">
                <img src="@/assets/map/actions.png">
            </button>
            <button :class="{ selected: selectedTool == 'observations' }" id="observations">
                <img src="@/assets/map/eye.png">
            </button>
        </div>
    </div>
</template>

<script>

import alertToolComp from './rightTools/alertToolComp.vue'
import observationToolComp from './rightTools/observationToolComp.vue'
import actionsToolComp from './rightTools/actionsToolComp.vue'


export default {
    components: {
        alertToolComp,
        actionsToolComp,
        observationToolComp
    },
    data() {
        return {
            selectedTool: null,
            expanded: false
        }
    },
    emits: ['open', 'chooseMarker', 'addSpecies'],

    mounted() {
        const toolbarButtons = this.$refs.toolbar.querySelectorAll("button")
        toolbarButtons.forEach(element => {
            element.addEventListener("click", e => {
                this.$refs.observationTool.reset();
                this.expanded = false
                if (this.selectedTool == e.target.id) this.unpull()
                else {
                   
                    this.$emit("open", e.target.id)
                    this.selectedTool = e.target.id
                }
            })
        });
    },

    methods: {
        handleAddObservation(observation) {
            this.$emit("addObservation", observation)
        },
        handleSetExpand(bool) {
            this.expanded = bool
        },
        handleElementClicked(element) {
            this.$emit("chooseMarker", element)
        },
        unpull(withExpand) {
            this.selectedTool = null
            if(withExpand) this.expanded = false
        },
        pull(tool, action, data) {
            this.selectedTool = tool
            if (action == 'setData') {
                this.$nextTick(() => {
                    if (data) {
                        if (tool == "alerts") this.$refs.alertTool.setData(data)
                        if (tool == "observations") this.$refs.observationTool.setData(data)
                    }
                })
            }
            if (action == 'setFilters') {
                this.$nextTick(() => {
                    if (tool == "observations") this.$refs.observationTool.setFilters(data)
                })
            }
        }
    }
}
</script>

<style scoped>
.right-tools {
    pointer-events: none;
    position: absolute;
    bottom: 1vw;
    right: 0;
    width: 59vw;
    height: 80vh;
    transform: translateX(85%);
    z-index: 3;
    display: flex;
    flex-direction: row-reverse;
    transition: 0.15s ease;
}

.right-tools.pulled {
    transform: translateX(0);
}

.right-tools.expanded {
    width: 100vw;
}


.tool-container {
    margin-right: 1vw;
    pointer-events: all;
    user-select: none;
    padding: 2vw;
    border-radius: 1vw;
    overflow: hidden;
    position: relative;
    background-color: rgb(255, 255, 255);
    width: 85%;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0.1vw 0.3vw;
}

.right-tools.expanded .toolbar {
    align-items: flex-start;
}

.right-tools .toolbar {
    pointer-events: none;
    padding: 2vw 2vw;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 2vw;
    height: 100%;
    width: 15%;
    flex-direction: column;
}

.right-tools .toolbar button {
    pointer-events: all;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: white;
    width: 5vw;
    height: 5vw;
    /* border: 0.01vw solid #E0E0E0; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0.1vw 0.3vw;
    border-radius: 100%;
    transition: 0.1s ease;
    cursor: pointer;
}

.right-tools .toolbar button.selected {
    filter: invert(0.95);
}

.right-tools .toolbar button:hover {
    scale: 1.1;
}

.right-tools .toolbar button img {
    width: 50%;
    pointer-events: none;
}

@media screen and (max-width: 100vh) {
    .right-tools {
        width: 100vw;
    }

    .right-tools .toolbar {
        width: 20%;
    }

    .right-tools .toolbar button {
        width: 10vw;

        height: 10vw;
    }

}
</style>