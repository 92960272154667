<template>
    <div v-if="show" class="loading">
        <img src="@/assets/map/loading.gif">
        <p v-if="message">{{ message }}...</p>
        <span v-if="subMessage">{{ subMessage }}</span>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            message: "Website loading",
            subMessage: null,
        }
    },
    methods:
    {
        close() {
            this.show = false
        },
        open(message, ...subMessage) {
            this.show = true;
            this.message = message;
            if (subMessage && typeof subMessage[0] === 'string') this.subMessage = subMessage[0];
        },
    }
}
</script>

<style scoped>
.loading {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 999999;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1vw;
}

.loading img {
    width: 2vw;

}

.loading p,
.loading span {
    font-family: 'Century Gothic';
}

.loading p {
    font-size: 1.4vw;
    color: rgb(78, 78, 78);
}

.loading span {
    font-size: 1.2vw;
    color: rgb(78, 78, 78);
}
</style>