<template>
    <div class="manage-container">
        <loadingComp ref="loading"></loadingComp>
        <popupComp @response="handlePopupResponse" ref="popup" />
        <div class="search-bar fully">
            <div class="search-bar-container">
                <img src="@/assets/home/search.png" />
                <input v-model="userInput" ref="input" placeholder="Search for permission..." type="text" />
            </div>
            <button @click="handleAdd" id="add">NEW</button>
        </div>

        <div class="row" style="background-color: rgba(0, 0, 0, 0.027)">
            <section class="results-count">
                <label>{{ 0 }} results found</label>
            </section>
        </div>


        <template v-if="permissionToEdit == null ">
            
            <div class="content-scroller">
                <div v-for="(permission, key) in $store.state.permissions" :key="key" class="row">
                    <section>
                        <label>Unique code</label>
                        <p>{{ permission.Permission_code }}</p>
                    </section>
    
                    <section>
                        <label>Title</label>
                        <p>{{ permission.En_name }}</p>
                    </section>
    
                    <div class="btns">
                        <button @click="handleEdit(permission)" class="edit">Edit</button>
                        <button @click="handleDelete(permission)" class="delete">Delete</button>
                    </div>
                </div>
            </div>
            
        </template>

        <EditComp :title="title"  :rows="permissionRows" @new="handleNewPermission" @update="handlePermissionUpdate" @cancel="permissionToEdit = null" :newObj="newPermission"
            :data="permissionToEdit" v-if="permissionToEdit != null" />
    </div>
</template>

<script>

import popupComp from '@/components/popupComp.vue'
import EditComp from './EditComp.vue';
import loadingComp from '@/components/loadingComp.vue'
export default {
    components: {
        EditComp,
        popupComp,
        loadingComp
    },
    async mounted(){
        this.$refs.loading.open("Pulling permissions")
        await this.$store.dispatch("getAllPermissions")
        this.$refs.loading.close()
    },
    methods: {
        handleAdd(){
            this.newPermission = true;
            this.permissionToEdit = {}
        },
        handleNewPermission(data){
            this.$refs.loading.open("Creating permission")
            fetch(process.env.VUE_APP_BACKEND_V2_URL + "/newPermission",
                {
                    method : "POST",
                    body : JSON.stringify({name : data.En_name, code : data.Permission_code})
                }
            ).then(res=>{
                res.json().then(json=>{
                    if(!json.error)this.$store.commit("addPermission", json)
                    this.permissionToEdit = null
                })
                this.$refs.loading.close()
            })
        }, 
        handleEdit(permission){
            this.permissionToEdit = permission; 
        },
        handlePermissionUpdate(data){
            let store_permission_index = this.$store.state.permissions.findIndex(e => e.Permission_ID == data.Permission_ID)
            if(store_permission_index !== -1){
                this.$refs.loading.open("Updating permission")
                fetch(process.env.VUE_APP_BACKEND_V2_URL + "/updatePermission/" +  data.Permission_ID,
                    {
                        method : "POST",
                        body : JSON.stringify({name : data.En_name, code : data.Permission_code})
                    }
                ).then(res=>{
                    res.json().then(json=>{
                        if(!json.error){
                            this.$store.commit("updatePermission", {key : store_permission_index, data : data});
                        }
                        this.permissionToEdit = null
                        this.$refs.loading.close()
                    })
                }) 
            }
           
        },
        handleDelete(permission){
            this.$refs.popup.show("Are you sure you want to delete this permission ?", "dilem-revert", 'delete', permission)
        },
        handlePopupResponse(action , response, data){
            if(action == 'delete' && response == 'ok')
            {
                this.$refs.loading.open("Deleting permission")
                fetch(process.env.VUE_APP_BACKEND_V2_URL + "/deletePermission/" +data.Permission_ID ,
                    {
                        method : "DELETE",
                    }
                ).then(res=>{
                    res.json().then(json=>{
                        if(!json.error){

                            this.$store.commit("deletePermission", data.Permission_ID)
                        }
                        this.permissionToEdit = null
                        this.$refs.loading.close()
                    })
                })                 
            }
            
            
        },
    },
    data() {
        return {
            title : 'Permission',
            permissionToEdit: null,
            newPermission : false,
            permissionRows : [
                {
                    title : 'Code',
                    key : 'Permission_code',
                    type : 'text'
                },
                {
                    title : 'Name',
                    key : 'En_name',
                    type : 'text'
                },
            ],
        }
    }
}
</script>
