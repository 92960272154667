import { createStore } from "vuex";
//images
import charleDeGaulleImage from "@/assets/image/airport/charle-de-gaulle.webp";
import zagrebImage from "@/assets/image/airport/zagreb.webp";
import ClujAvramIancuImage from "@/assets/image/airport/cluj-avram-iancu.webp";
import MilanMalpensa from "@/assets/image/airport/milan-malpensa.webp";
import { getObservations, getSpecies, auth } from "@/app/firebase";
import { onAuthStateChanged } from "firebase/auth";
import observationsToComplete from "./observationsToComplete";
observationsToComplete
import observations from "./observations";
observations

console.log(process.env.VUE_APP_BACKEND_V2_URL);

const store = createStore({
  state() {
    return {
      logged: null,
      user: {
        accessToken: null,
        permissions: null,
      },
      observations: [],
      inventories: null,
      observationsToComplete: null,
      species: [],
      /** State that indicates if a confirmation should 
       * be asked before unpulling the component. For now 
       * this is only activated when editing a form or a 
       * field key */
      isTopToolsUnpullLocked: false,
      /** Contains states related to the form management */
      form: {
        /** Contains the last selected item in the left menu */
        formLeftMenuLastSelectedItem: null,
        /** Contains minimal forms fetched from backend */
        formsMinimal: [],
        /** Indicates if the initial forms fetch has been completed */
        initialFormsFetchCompleted: false,
        /** Contains form categories */
        categories: new Set(),
        /** Contains form fields keys */
        formKeys: [],
        /** Indicates if the initial form keys fetch has been completed */
        initialFormKeysFetchCompleted: false,
      },
      alerts: [
        {
          lat: 49.015657,
          lng: 2.582107,
          priority: "high",
          "last modified": "20-09-2004, 20:05",
          type: "Rat",
        },
      ],
      airports: {
        ParisCharlesDeGaulle: {
          name: "Paris Charles de Gaulle Airport",
          image: charleDeGaulleImage,
          latitude: 49.00782,
          longitude: 2.550836,
        },
        ClujAvramIancu: {
          name: 'Cluj "Avram Iancu” International Airport',
          image: ClujAvramIancuImage,
          latitude: 46.782338,
          longitude: 23.687878,
        },
        Zagreb: {
          name: "Zagreb Airport",
          image: zagrebImage,
          latitude: 45.740711,
          longitude: 16.069497,
        },
        MilanMalpensa: {
          name: "Milan Malpensa Airport",
          image: MilanMalpensa,
          latitude: 45.627755,
          longitude: 8.726051,
        },
      },

      roles: null,
      permissions: null,
      accounts: null,
      activeAirport: {},
      activeAirportKey: null,
      filteredObservations: [],
    };
  },
  getters: {
    getAlertColor: (state) => (priority) => {
      state;
      if (priority == "high") return "#ff3523";
      if (priority == "normal") return "#ffc123";
      else return "black";
    },
    /**
     * Capitalizes the first letter of a string
     *
     * @param str - string to capitalize
     * @returns {string} - capitalized string
     * @version 1.0.1
     */
    capitalizeFirstLetter: () => (str) => {
      return str && typeof str === "string" ? str.charAt(0).toUpperCase() + str.slice(1) : str;
    },
  },
  actions: {
    async getObservations(context) {
      const observations = await getObservations();

      context.commit("setObservations", observations);

      return 0;
    },

    async getObservationsToComplete(context) {
      return new Promise((resolve) => {
        if (this.state.observationsToComplete !== null) {
          resolve()
          return
        }

        fetch(process.env.VUE_APP_BACKEND_V2_URL + '/getAllObservationsToComplete', {
          method: "GET"
        }).then(res => {
          res.json().then(json => {
            if (!json.error) {
              json.map(o => {
                if (o.date && o.date.seconds) o.date = o.date.seconds
              })
              context.commit("setObservationsToComplete", JSON.parse(JSON.stringify(json)))
            }
            resolve();
          })
        })

      })
    },

    async getAirportInventorys({state})
    {
      return new Promise((resolve)=>{
        if(state.inventories !== null)
        {
          resolve();
          return;
        }
        state.inventories = "waiting"
        fetch(process.env.VUE_APP_BACKEND_V2_URL + "/getAirportInventoryCodes/cdg", 
          { method : "GET" }
        ).then(res=>{
          res.json().then(json=>{
            if(!json.error){
              state.inventories = json;
            }
            resolve();
          })
        })
      })
    },

    async getAllAccounts({ commit, state }) {
      return new Promise((resolve) => {
        if (state.accounts !== null) {
          resolve();
          return;
        }
        state.accounts = "waiting"
        fetch(process.env.VUE_APP_BACKEND_V2_URL + "/getAllUsers", {
          method: "GET",
        }).then(res => {
          res.json().then(accounts => {
            commit("setAccounts", accounts)
            resolve()
          })
        })
      })
    },
    async getAllRoles({ commit, state }) {
      return new Promise((resolve) => {
        if (state.roles !== null) {
          resolve();
          return;
        }
        state.roles = "waiting"
        fetch(process.env.VUE_APP_BACKEND_V2_URL + "/getAllRoles", {
          method: "GET",
        }).then(res => {
          res.json().then(roles => {
            commit("setRoles", roles)
            resolve()
          })
        })
      })
    },
    async getAllPermissions({ commit, state }) {
      return new Promise((resolve) => {
        if (state.permissions !== null) {
          resolve();
          return;
        }
        state.permissions = "waiting"
        fetch(process.env.VUE_APP_BACKEND_V2_URL + "/getAllPermissions", {
          method: "GET",
        }).then(res => {
          res.json().then(permissions => {
            commit("setPermissions", permissions)
            resolve()
          })
        })
      })
    },
    async getUserPermissions({ commit, state }) {
      console.log(state.user);
      return new Promise((resolve) => {
        if (state.user.permissions !== null) {
          resolve();
          return;
        }
        fetch(process.env.VUE_APP_BACKEND_V2_URL + "/getUserPermissions", {
          method: "GET",
          headers:
          {
            Authorization: state.user.accessToken
          }
        }).then((res) => {
          res.json().then((permissions) => {
            commit("setUserPermissions", permissions);
            resolve();
          });
        });
      });
    },
    async getSpecies(context) {
      const species = await getSpecies();
      context.commit("setSpecies", species);
      return 0;
    },
    async waitLogged({ state }) {
      return new Promise((resolve) => {
        if (state.logged != null) {
          resolve();
        }
        const interval = setInterval(() => {
          if (state.logged != null) {
            clearInterval(interval);
            resolve();
          }
        }, 100);
      });
    },

    // * --------- Forms --------- * //
    /**
     * Logs a message to the console with a timestamp and the component name (only in development mode)
     * 
     * @param {string} componentName 
     * @param {string} message 
     * @param {string} level 
     * @returns {void}
     * 
     * @version 1.0.1
     */
    debugLog(_, { componentName = 'UnknownComponent', message, level = 'info', attachment }) {
      const shouldLog = process.env.NODE_ENV === 'development' || level === 'error';
      if (!shouldLog) return;

      const timestamp = new Date().toLocaleTimeString();
      const levelColors = { debug: '', info: 'color: lightblue;', warn: 'color: orange;', error: 'color: red;' };
      const levelColor = levelColors[level] || '';
      const logMessage = `%c[${timestamp}] |${level.toUpperCase()}| ${componentName} -> ${message}`;

      // Check if there's an attachment and log accordingly
      if (attachment) console.log(logMessage, levelColor, attachment);
      else console.log(logMessage, levelColor);
      return;
    },
    /**
     * Fetches minimal forms from the backend and sets them in the store
     * 
     * @returns {void}
     * @version 1.0.1
     */
    async getFormsMinimal({ commit }) {
      return new Promise((resolve) => {
        fetch(process.env.VUE_APP_BACKEND_V2_URL + '/getFormsMinimal', {
          method: "GET"
        }).then(res => {
          res.json().then(json => {

            commit("setFormsMinimal", json.filter(e => !e.fakeForm));

            const categories = new Set(json
              .map(e => e.form_category)
              .filter((value, index, self) => value && self.indexOf(value) === index)
              .map(value => value === "" ? "None" : value));


            if (categories.has("None")) {
              categories.delete("None");
              categories.add("None");
            }

            commit("setCategories", categories);

            // DEBUG ---------------------
            this.dispatch('debugLog', {
              componentName: 'Store',
              message: 'fetching forms & categories',
              attachment: [json, categories],
              level: 'debug'
            });
            // ---------------------------

            resolve();
          })
        })
      })
    },

    /**
     * Handles the initial minimal forms fetch,
     * Triggered on Mount of the form management components 
     * (if permissions allow it)
     * 
     * @returns {void}
     * @version 1.0.0
     */
    async firstMinimalFormsFetch({ dispatch }) {
      if (!this.state.form.initialFormsFetchCompleted) {
        await dispatch("getFormsMinimal");
        this.state.form.initialFormsFetchCompleted = true;

        // DEBUG ---------------------
        this.dispatch('debugLog', {
          componentName: 'Store',
          message: 'Initial minimal forms fetch completed',
          level: 'info'
        });
        // ---------------------------
      }
    },

    /**
     * Refreshes the minimal forms in the store
     * 
     * @returns {void}
     * @version 1.0.0
     */
    async refreshMinimalForms() {
      await this.dispatch("getFormsMinimal");
    },

    /**
     * Fetches form keys from the backend and sets them in the store
     * 
     * @returns {void}
     * @version 1.0.0
     */
    async getFormKeys({ commit }) {
      return new Promise((resolve) => {
        fetch(process.env.VUE_APP_BACKEND_V2_URL + '/getFormKeys', {
          method: "GET"
        }).then(res => {
          res.json().then(json => {
            commit("setKeys", json.filter(e => Object.keys(e).length > 0)); // Filter out empty objects (fake key)
            resolve();
          })
        })
      })
    },

    /**
     * Handles the initial form keys fetch,
     * Triggered on Mount of the form management components
     * (if permissions allow it)
     * 
     * @returns {void}
     * @version 1.0.0
     */
    async firstFormKeysFetch({ dispatch }) {
      if (!this.state.form.initialFormKeysFetchCompleted) {
        await dispatch("getFormKeys");
        this.state.form.initialFormKeysFetchCompleted = true;

        // DEBUG ---------------------
        this.dispatch('debugLog', {
          componentName: 'Store',
          message: 'Initial form keys fetch completed',
          level: 'info'
        });
        // ---------------------------
      }
    },

    /**
     * Refreshes the form keys in the store
     * 
     * @returns {void}
     * @version 1.0.0
     */
    async refreshFormKeys() {
      await this.dispatch("getFormKeys");
    },

    // * ------- Inventory ------- * //
    async getAirportInventoryCodes({ commit }, airport) {
      return new Promise((resolve) => {
        fetch(process.env.VUE_APP_BACKEND_V2_URL + `/getAirportInventoryCodes/${airport}`, {
          method: "GET",
        }).then(res => {
          res.json().then(inventories => {
            commit("setInventories", inventories)
            commit("setInventoriesCodes", inventories.map(e => e.code))

            // DEBUG ---------------------
            this.dispatch('debugLog', {
              componentName: 'Store',
              message: 'fetching inventories',
              attachment: inventories,
              level: 'debug'
            });
            // ---------------------------

            resolve()
          })
        })
      })
    },

    async getAirportInventoryCodesIDOnly({ commit }, airport) {
      return new Promise((resolve) => {
        fetch(process.env.VUE_APP_BACKEND_V2_URL + `/getAirportInventoryCodesIDOnly/${airport}`, {
          method: "GET",
        }).then(res => {
          res.json().then(inventories => {
            commit("setInventoriesCodes", inventories.map(e => e.code))

            // DEBUG ---------------------
            this.dispatch('debugLog', {
              componentName: 'Store',
              message: 'fetching inventories codes',
              attachment: inventories.map(e => e.id),
              level: 'debug'
            });
            // ---------------------------

            resolve()
          })
        })
      })
    },

    async firstInventoryFetch({ dispatch }) {
      if (!this.state.initialInventoryFetchCompleted) {
        const AirportsAssociations = new Map([
          ["ParisCharlesDeGaulle", "CDG"],
          // ["ClujAvramIancu", "CLJ"],
          // ["Zagreb", "ZAG"],
          ["MilanMalpensa", "MXP"]
        ]);

        await dispatch("getAirportInventoryCodes", AirportsAssociations.get(this.state.activeAirportKey));
        this.state.initialInventoryFetchCompleted = true;

        // DEBUG ---------------------
        this.dispatch('debugLog', {
          componentName: 'Store',
          message: 'Initial inventory fetch completed',
          level: 'info'
        });
        // ---------------------------
      }
    }

    // * ------------------------- * //
  },

  mutations: {
    setActiveAirport(state, airport) {
      state;
      state.activeAirport = state.airports[airport];
      state.activeAirportKey = airport;
    },
    setRoles(state, roles) {
      state.roles = roles
    },

    // ? --------- Forms --------- ? //
    /**
     * Self-explanatory setter for minimal forms
     * 
     * @param {*} state
     * @param {Array} formsMinimal
     * @returns {void}
     * @version 1.0.0
     */
    setFormsMinimal(state, formsMinimal) {
      state.form.formsMinimal = formsMinimal;
    },
    /**
     * Self-explanatory setter for form categories
     * 
     * @param {*} state
     * @param {Set} categories
     * @returns {void}
     * @version 1.0.0
     */
    setCategories(state, categories) {
      state.form.categories = categories;
    },
    /**
     * Self-explanatory setter for form keys
     * 
     * @param {*} state
     * @param {Array} keys
     * @returns {void}
     * @version 1.0.0
     */
    setKeys(state, keys) {
      state.form.formKeys = keys;
    },
    setUserPermissions(state, permissions) {
      state.user.permissions = permissions;
    },
    setPermissions(state, permissions) {
      state.permissions = permissions
    },
    setAccounts(state, accounts) {
      state.accounts = accounts
    },
    addAccount(state, account) {
      state.accounts.unshift(account)
    },
    addPermission(state, permission) {
      state.permissions.unshift(permission)
    },
    addInventory(state, inventory) {
      state.inventories.unshift(inventory)
    },
    addObservation(state, observation) {
      state.observations.unshift(observation)
    },
    setUserData(state, data) {
      state.user[data.key] = data.content
    },
    setFilteredObservations(state, observations) {
      state.filteredObservations = observations;
    },
    setObservations(state, observations) {
      state.observations = observations;
    },
    setObservationsToComplete(state, observations) {
      observations.map(e => e.toComplete = true)
      state.observationsToComplete = observations;
    },
    setSpecies(state, species) {
      state.species = species;
    },
    updateRole(state, content) {
      Object.assign(state.roles[content.key], content.data);
    },
    updatePermission(state, content) {
      Object.assign(state.permissions[content.key], content.data);
    },
    deletePermission(state, id) {
      //find index
      const index = state.permissions.findIndex((e) => e.Permission_ID == id);
      //delete permission
      if (index != -1) state.permissions.splice(index, 1);
    },
    setLogged(state, bool) {
      state.logged = bool;
    },
    addRole(state, role) {
      state.roles.unshift(role);
    },
  },
});

onAuthStateChanged(auth, (user) => {
  if (user) {
    store.commit("setUserData", { key: "accessToken", content: user.accessToken });
    store.commit("setUserData", { key: "email", content: user.email });
    store.commit("setLogged", true);
  } else {
    store.commit("setLogged", false);
  }
});

export default store;
