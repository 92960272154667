<template>
    <div class="top-tools">
        <div :class="{ pulled: selectedTool != null }" class="tool-container">
            <administrationComp v-if="selectedTool == 'administration'"></administrationComp>
            <planningComp v-else-if="selectedTool == 'planning'"></planningComp>
            <formsComp v-else-if="selectedTool == 'forms'"></formsComp>
            <span v-else id="no-result-container">
                <img src="@/assets/map/in-development.png">
                In development...
            </span>
        </div>
        <div ref="toolbar" class="toolbar">
            <button :class="{ selected: selectedTool == 'administration' }" id="administration">Administration</button>
            <button :class="{ selected: selectedTool == 'forms' }" id="forms">Forms</button>
            <button :class="{ selected: selectedTool == 'planning' }" id="planning">Planning</button>
            <button :class="{ selected: selectedTool == 'statsPredictions' }" id="statsPredictions">Stats and
                predictions</button>
        </div>

    </div>
</template>

<script>
import administrationComp from '@/components/map/topTools/administrationComp.vue'
import formsComp from '@/components/map/topTools/formsComp.vue'
import planningComp from "@/components/map/topTools/planningComp.vue"
export default {
    data() {
        return {
            selectedTool: null
        }
    },
    components: {
        administrationComp, formsComp, planningComp
    },
    mounted() {
        const toolbarButtons = this.$refs.toolbar.querySelectorAll("button")
        toolbarButtons.forEach(element => {
            element.addEventListener("click", e => {
                if (this.selectedTool == e.target.id) this.selectedTool = null
                else {
                    this.$emit("open")
                    this.selectedTool = e.target.id
                }
            })
        });
    },

    methods: {
        unpull() {
            this.selectedTool = null
        }

    }
}
</script>

<style scoped>
.top-tools {
    pointer-events: none;
    position: absolute;
    padding-top: 2vw;
    top: 0;
    left: 2%;
    width: 80%;
    height: 95vh;
    z-index: 3;
    display: flex;
    flex-direction: column-reverse;

}

.toolbar {
    height: 10%;
    width: 100%;
    display: flex;
    gap: 1.5vw;
}

.tool-container {
    border-radius: 1vw;
    margin-top: 1vw;
    padding: 2vw;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0.1vw 0.3vw;
    height: 90%;
    transform: translateX(-150%);
    transition: 0.3s ease;
    overflow: hidden;
}

.tool-container.pulled {
    pointer-events: all;
    transform: translateX(0);
}

.toolbar button {
    cursor: pointer;
    pointer-events: all;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0.1vw 0.3vw;
    font-size: 0.8vw;
    padding: 1vw 0.5vw;
    height: 4vw;
    color: var(--black);
    width: 11vw;
    border-radius: 3vw;
    background-color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Century Gothic';
    transition: 0.1s ease;
}

.toolbar button:hover {
    scale: 1.1;
}


.toolbar button.selected {
    filter: invert(1);
    font-weight: bold;
}

#no-result-container {
    z-index: 1;
    position: absolute;
    flex-direction: column;
    height: 2vw;
    font-size: 1.3vw;
    align-items: center;
    justify-content: center;
    left: 50%;
    top: 55%;
    display: flex;
    gap: 0.5vw;
    opacity: 0.6;
    transform: translateX(-50%) translateY(-50%);
    font-family: 'Century Gothic';
}

#no-result-container img {
    width: 4vw;
}

@media screen and (max-width: 100vh) {
    .top-tools {
        padding-top: 22vw;
        width: 94%;
        gap: 1vw;
        left: 3%;
    }

    .toolbar {
        flex-wrap: nowrap;
        gap: 2%;
    }

    .toolbar button {
        border-radius: 10vw;
        font-size: 2.3vw;
        height: 100%;
        max-height: 10vw;
        width: 30vw;
    }
}
</style>