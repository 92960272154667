<template>
    <div ref="mapExportContainer" class="map-export-container">
        <loadingComp ref="loading"></loadingComp>
        <slot></slot>
        <AdditionalContent ref="additionalContent" v-if="exporting" />
        <div class="bottom-btns">
            <button @click="handleExportSeeMore" id="export-show-additional-btn">Show more</button>
            <div class="group">
                <select v-model="exportFormat" id="select-export-format">
                    <option selected>PDF</option>
                    <option>CSV</option>
                </select>
                <button @click="handleExport" id="export-btn">EXPORT</button>
            </div>
        </div>

        <div v-if="exporting && exported" class="send-export">
            <h3>Exported successfully</h3>
            <div class="container">
                <div class="users">
                    <div class="user" v-for="(account, key) in $store.state.accounts" :key="key">
                        <p>{{ account.mail }}</p>
                        <button @click="mailsSent.push(account.mail)"
                            :class="{ checked: mailsSent.find(e => e == account.mail) }">
                            <span>SEND</span>
                            <img src="@/assets/map/checked.png">
                        </button>

                    </div>
                </div>
                <div class="right">
                    <p>Your export was downloaded, you can send it to other registered users just by clicking send !
                        When you're done just click 'Close'</p>
                    <button @click="handleClose" id="close-btn">Close</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import html2pdf from "html2pdf.js";
html2pdf //TODO : Remove from project
import exportCss from "@/assets/css/exportCss.js"
import papa from "papaparse";
import loadingComp from "../loadingComp.vue";
import AdditionalContent from './export/AdditionalContent.vue';


export default {
    data() {
        return {
            exporting: false,
            exported: false,
            mailsSent: [],
            exportFormat: "PDF"
        }
    },
    components: {
        loadingComp,
        AdditionalContent
    },
    computed:
    {
        allKeys() {
            const allKeys = Array.from(new Set(this.$store.state.filteredObservations.flatMap(Object.keys)));
            return allKeys;
        },
        completeData() {
            // Ensure each object has all keys
            const completeData = this.$store.state.filteredObservations.map(item => {
                const completeItem = {};
                this.allKeys.forEach(key => {
                    completeItem[key] = item[key] || '';
                });
                return completeItem;
            });
            return completeData;
        }
    },

    mounted() {
    
    },

    methods: {
        async handleExport() {
            if (this.exportFormat == "PDF") {
                this.$refs.loading.open("Exporting PDF")
                await this.exportPDF();
                this.openAfterExport()
                //this.$emit('showPopup', { title: "Exported successfully", type: "validation" })
            }
            if (this.exportFormat == "CSV") {
                this.$refs.loading.open("Exporting CSV")
                await this.exportCSV();
                this.openAfterExport()
                //this.$emit('showPopup', { title: "Exported successfully", type: "validation" })
            }
            this.exported = true
        },
        async openAfterExport() {
            await this.$store.dispatch("getAllAccounts")
            this.$refs.loading.close()
            this.exported = true
        },
        exportCSV() {
            return new Promise((resolve) => {
                const csv = papa.unparse(this.completeData);
                const blob = new Blob([csv], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob);
                // Create a link element and trigger a download
                const a = document.createElement('a');
                a.setAttribute('href', url);
                a.setAttribute('download', 'data.csv');
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                resolve()
            })
        },
        exportPDF() {
            return new Promise((resolve) => {
                const map = document.getElementById('map')
                const element_export = document.createElement("div");
                element_export.style.width = "1920px";
                
                const map_clone = map.cloneNode(true)
                map_clone.style.width = "1920px";
                map_clone.style.height = "1080px";
                element_export.appendChild(map_clone)
                // TODO : Add it (leaflet_style) server side 
                const leaflet_style = document.createElement("style");
                leaflet_style.innerHTML = exportCss
                element_export.appendChild(leaflet_style)

                console.log(element_export);
                element_export.appendChild(document.querySelector(".export-additional-content").cloneNode(true))
                fetch(process.env.VUE_APP_BACKEND_V2_URL + "/toPdf", {
                method: "POST",
                body : JSON.stringify({
                    HTML : element_export.outerHTML
                })
                }).then(res => {
                    console.log(res.json().then(json => {
                        const buffer = new Uint8Array(json.PDF.data) 
                        const blob = new Blob([buffer], {type : "application/pdf"})
                        const url = URL.createObjectURL(blob)
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = 'content.pdf';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        resolve();
                        
                    }));
                })
                
                // console.log(element_export);
                // var opt = {
                //     margin: [20, 20, 20, 20], // Set appropriate margins
                //     autoPaging: 'text',
                //     pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
                //     filename: 'file.pdf',
                //     image: { quality: 1 },
                //     html2canvas: {
                //         allowTaint: true,
                //         letterRendering: true,
                //         logging: false, scale: 1.2, useCORS: true
                //     },
                //     jsPDF: { compress: false, unit: 'px', format: [1920, 1080], orientation: 'l' }
                // }
                // html2pdf().from(element_export).set(opt).save().then(() => {
                //     resolve();
                // });
            })

        },
        handleClose() {
            this.exporting = false
            this.mailsSent = []
            this.exported = false
            this.$emit('close')
        },
        handleExportSeeMore() {
            this.$refs.additionalContent.toggleShow();
        },
        open() {
            this.exporting = true
            this.$refs.mapExportContainer.classList.add('export')
        },
        close() {
            this.exporting = false
            this.$refs.mapExportContainer.classList.remove('export')
        }
    }
}
</script>

<style></style>

<style scoped>
.map-export-container {
    width: 1920px;
    height: 1080px;
    overflow: hidden;
    transition: 0.3s ease;
    width: 100%;
    height: 100%;
    outline: 0.5vw solid white;
    z-index: 99;
}




.map-export-container.export .bottom-btns {
    display: flex;
}

.map-export-container .bottom-btns {
    display: none;
    position: absolute;
    z-index: 999;
    bottom: 0;
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    left: 0;
    width: 100%;
    height: 100px;
}

.map-export-container .bottom-btns .group {
    height: 100%;
    display: flex;
    gap: 20px;
}

.map-export-container .bottom-btns button,
.map-export-container .bottom-btns select {
    height: 100%;
    width: 300px;
    border: 0;
    outline: 1px solid rgb(255 65 0 / 46%);
    text-align: center;
    background: white;
    color: black;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0.1vw 0.3vw;
    font-size: 30px;
    transition: 0.1s ease;
}

.map-export-container .bottom-btns select option {
    font-size: 20px;
}


.map-export-container button:hover {
    scale: 1.1;
}

.map-export-container.export {
    width: 1920px;
    position: absolute;
    height: 1080px;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) scale(0.5);

}


.send-export {
    position: absolute;
    width: 100%;
    z-index: 9999;
    height: 100%;
    display: flex;
    flex-direction: column;
    left: 0;
    top: 0;
    background-color: rgb(255, 255, 255);
}

.send-export h3 {
    font-family: 'Century Gothic';
    text-align: center;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 13%;
    font-size: 50px;
    color: white;
    background-color: #1d1d1d;
    border-top: 1px solid rgb(185, 185, 185);
    border-bottom: 1px solid rgb(185, 185, 185);
}

.send-export .container {
    padding-top: 40px;
    position: relative;
    display: flex;
    width: 100%;
    height: 87%;
}

.send-export .users {
    height: 100%;
    direction: rtl;
    width: 70%;
    border-right: 1px solid rgb(185, 185, 185);
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px;
    padding-top: 10px;
    padding-bottom: 40px;
    justify-content: flex-start;
    gap: 25px;
}

.send-export .users .user {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row-reverse;
    gap: 50px
}

.send-export .users .user p {
    width: 80%;
    font-family: 'Century Gothic';
    text-align: left;
    font-size: 40px;
    outline: 1px solid rgb(192, 192, 192);
    border-radius: 30px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 120px;
    color: var(--green);
}



.send-export .users .user button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: rgba(226, 226, 226, 0.562);
    height: 120px;
    border-radius: 30px;
    color: var(--black);
    border: 1px solid rgba(128, 128, 128, 0.37);
    width: 20%;
    font-size: 40px;
    padding: 0 20px;
}

.send-export .users .user button.checked {
    cursor: none;
    pointer-events: none;
    background: none;
    transition: 0.3s;
}

.send-export .users .user button span {
    font-family: 'Century Gothic';
    letter-spacing: 5px;
}

.send-export .users .user button img {
    display: none;
}

.send-export .users .user button.checked span {
    display: none;
}

.send-export .users .user button.checked img {
    display: block;
}


.send-export .right {
    width: 30%;
    height: 100%;
}

.send-export .right p {
    padding: 20px;
    font-size: 30px;
    font-family: 'Century Gothic';
    text-align: center;
}

.send-export .right #close-btn {
    width: 80%;
    height: 100px;
    margin-left: 10%;
    border: 1px solid rgb(167, 167, 167);
    font-size: 40px;
    cursor: pointer;
    font-family: 'Century Gothic';
}
</style>