<template>
    <skeletonField v-model="model" @callToDeleteSelf="deleteSelf(index)" :title="title">
        <template v-slot:content>
            <div class="form-field__form-field-content">
                <fieldKeySelect v-model="model.field_key" :keys="$store.state.formKeys" />
                <fieldLabelInput v-model="model.field_label" :placeholder="'This text input asks the user to...'" />
                <fieldHintInput v-model="model.field_hint" isTextArea
                    :placeholder="'This hint the user that the input should be...'" />

                <br>
                <hr>
                <br>

                Try it:
                <textarea :value="model.field_label" disabled />
                <input type="number" v-model="localTestingValue" @change="checkMax()" :min="model.input_min"
                    :max="model.input_max" :placeholder="model.field_hint">
            </div>
        </template>
        <template v-slot:options>
            <div class="parameter-group__option">
                <button class="--option">
                    <!-- Min. Answers : -->
                    <label>Min.</label>
                    <input type="number" @change="checkMax()" v-model="model.input_min" :max="model.input_max">
                </button>

                <button class="--option">
                    <!-- Max. Answers : -->
                    <label>Max.</label>
                    <input type="number" @change="checkMax()" v-model="model.input_max" :min="model.input_min">
                </button>
            </div>
            <div class="parameter-group__option">
                <button class="--option">
                    <label>🔑 Required :</label>
                    <input type="checkbox" v-model="model.field_required">
                </button>
            </div>
        </template>
    </skeletonField>
</template>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<script setup>
import { defineModel, defineProps, ref } from 'vue';
import skeletonField from './skeletonField.vue'
import fieldKeySelect from "@/components/map/topTools/forms/customElements/fieldKeySelect.vue";
import fieldLabelInput from '@/components/map/topTools/forms/customElements/fieldLabelInput.vue';
import fieldHintInput from '@/components/map/topTools/forms/customElements/fieldHintInput.vue';

const model = defineModel();
const localTestingValue = ref(null)

defineProps({
    title: String,
    index: Number,
    deleteSelf: Function
});

function checkMax() {
    if (
        localTestingValue.value < this.model.input_min
        && this.model.input_min) {

        localTestingValue.value = this.model.input_min;

    } else if (
        localTestingValue.value > this.model.input_max
        && this.model.input_max) {

        localTestingValue.value = this.model.input_max;
    }
}

</script>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<style scoped lang="scss">
#field-element:invalid {
    box-shadow: var(--red-glow-shadow);
}

.form-field__form-field-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 89%;
    gap: 0.2vw;

    input {
        border: 0.01vw solid var(--grey);
        border-radius: 0.1vw;
        padding: 0.2vw;

        font-size: 16px;
        color: var(--black-light);
    }
}

.parameter-group__option {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5vw;

    .--option {
        display: inline-flex;
        flex-direction: row;
        align-items: center;

        background-color: var(--grey-light);
        border-radius: var(--small-radius);
        border: 0.01vw solid var(--grey-darker);

        padding: 0.2vw;
        font-size: 0.9vw;

        label {
            margin-right: 0.2vw;
            /* filter: grayscale(100%); */
        }

        input {
            display: inline-flex;
            flex-direction: row;

            width: 3vw;
            height: 1.1vw;
            font-size: 0.9vw;
            padding: 0.2vw;

            &:focus {
                border: none;
                /* outline: 0.01vw solid var(--grey-darker); */
                /* border-radius: var(--small-radius); */
            }
        }

        input[type="checkbox"] {

            width: 1vw;
            height: 1vw;

            border-radius: var(--small-radius);
            border: none;
            outline: none;
        }
    }
}
</style>
