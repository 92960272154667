<template>
    <navbarComp :handleAirportRoutes="true" :pull="true" ref="navbar"></navbarComp>
    <main ref="main">
        <div id="map"></div>
        <observationComp ref="observationContainer"></observationComp>
        <toolbar ref="toolbar" @open="handleToolBarOpen"></toolbar>
    </main>
</template>

<script>
import navbarComp from '@/components/navbarComp.vue'
import L from "leaflet";
export default {
    components: {
        navbarComp
    },
    mounted() {
        this.map = L.map("map")
        this.map.setView([48.743, 2.40129], 13);
        //retire les boutons zoom en haut a droite (souvent inutiles) et les contributeurs en bas a droite
        this.map.zoomControl.remove();
        this.map.attributionControl.remove();

        L.tileLayer(
            "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png",
            {
                maxZoom: 19,
            }
        ).addTo(this.map)
    }
}
</script>

<style>
#map {
    width: 100%;
    height: 100vh;
    cursor: default;
    z-index: 1;
}
</style>