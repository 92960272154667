<template>
    <skeletonField v-model="model" @callToDeleteSelf="deleteSelf(index)" :title="title">
        <template v-slot:content>
            <div class="form-field__form-field-content">

                <!-- Picture Label -->
                <textarea type="text" v-model="model.field_label" id="field-element" required
                    placeholder="This is the text that will be displayed above the picture...">
                </textarea>

                <!-- Button -->
                <button disabled>
                    <h4>📸</h4>
                </button>
            </div>
        </template>
        <template v-slot:options>
            <div class="parameter-group__option">
                <button class="--option">
                    <label>🔑 Required :</label>
                    <input type="checkbox" v-model="model.field_required">
                </button>
            </div>
        </template>
    </skeletonField>
</template>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<script setup>
import { defineModel, defineProps } from 'vue';
import skeletonField from './skeletonField.vue'

const model = defineModel();

defineProps({
    title: String,
    index: Number,
    deleteSelf: Function
});
</script>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<style scoped lang="scss">
#field-element:invalid {
    box-shadow: var(--red-glow-shadow);
}

.form-field__form-field-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    gap: 0.5vw;

    button {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 45%;
        border: 0.01vw solid var(--grey-darker);
        border-radius: var(--small-radius);

        height: 11.9vw;
        font-size: 1.2vw;
        padding: 0.3vw;

        &:disabled {
            background-color: var(--grey-dark);

            h4 {
                color: var(--black-light);
            }
        }
    }

    textarea {
        width: 100%;
        border: 0.01vw solid var(--grey-dark);
        background-color: var(--white);
        /* border: none; */
        border-radius: 0.1vw;
        padding: 0.2vw;

        width: 100%;
        font-size: 1.2vw;
        height: 3.3vw;
        resize: vertical;
        min-height: 3.3vw;
        max-height: 5.3vw;

        &:focus {
            outline: none;
            border: 0.01vw solid var(--grey-darker);
        }
    }
}

.parameter-group__option {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5vw;

    .--option {
        display: inline-flex;
        flex-direction: row;
        align-items: center;

        background-color: var(--grey-light);
        border-radius: var(--small-radius);
        border: 0.01vw solid var(--grey-darker);

        padding: 0.2vw;
        font-size: 0.9vw;

        label {
            margin-right: 0.2vw;
            /* filter: grayscale(100%); */
        }

        input {
            display: inline-flex;
            flex-direction: row;

            width: 3vw;
            height: 1.1vw;
            font-size: 0.9vw;
            padding: 0.2vw;

            &:focus {
                border: none;
                /* outline: 0.01vw solid var(--grey-darker); */
                /* border-radius: var(--small-radius); */
            }
        }

        input[type="checkbox"] {
            width: 1vw;
            height: 1vw;

            border-radius: var(--small-radius);
            border: none;
            outline: none;
        }
    }
}
</style>