<template>
    <div class="container-scroller">
        <div class="container">
            <h5>FILTERS</h5>
            <template v-for="(filter, key) in filters" :key="key">
                <section v-if="filter.type == 'text'" class="text">
                    <label>{{ filter.name }} :</label>
                    <input type="text" v-model="filter.content" />
                </section>

                <section @click="handleDateSectionClick" v-if="filter.type == 'date'" class="date">
                    <label>{{ filter.name }} :</label>
                    <input v-model="filter.content" type="date" />
                </section>

                <section v-if="filter.type == 'list'" class="list">
                    <label>{{ filter.name }} :</label>
                    <button @click="handleListAdd(filter)">Add</button>
                    <span @click="filter.content.pop(element)" v-for="(element, key) in filter.content" :key="key">{{
                element.content }}</span>
                </section>

                <template v-if="filter.type == 'checkbox'">
                    <h6 class="filter-list-title">{{ filter.name }} :</h6>
                    <div @click="filter.checked[key2] = !filter.checked[key2]"
                        v-for="(choice, key2) in filter.choices" :key="key2" class="filter">
                        <div class="checkbox-wrapper-18">
                            <div class="round">
                                <input v-model="filter.checked[key2]" disabled type="checkbox" :id="key2"
                                    :name="key2" />
                                <label :for="choice"></label>
                            </div>
                        </div>
                        <label class="checkbox-label">{{ key2 }}</label>
                    </div>
                </template>

            </template>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    props: {
        filters: Object
    },
    methods: {
        handleListAdd(filter) {
            if (filter.name == 'Species') this.$emit('addSpecies')
        },
    }
};
</script>

<style scoped>
.container-scroller {
    padding: 1vw 2vw;
    border-left: 0.1vw solid rgba(128, 128, 128, 0.329);
    height: 100%;
    margin-right: 1vw;
    overflow: auto;
}

.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1vw;
}

h5 {
    color: var(--black);
    font-size: 2vw;
    margin-bottom: 1vw;
    font-family: 'Century Gothic';

}

section {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: fit-content;
    gap: 2.5%
}

section.date {
    width: 100%;
}

section label {
    width: 30%;
    height: 3vw;
    display: flex;
    align-items: center;
    color: var(--black);
    border-bottom: 0.1vw solid lightgray;
    font-size: 1vw;
}

section input {
    font-size: 1vw;
    font-family: 'Century Gothic';
    color: var(--green);
    width: 65%;
    background-color: rgba(231, 231, 231, 0.508);
    padding: 1vw;
    border: none;
    cursor: pointer;
}


section input:focus {
    outline: none;
}

section.list button {
    background-color: rgba(231, 231, 231, 0.508);
    font-size: 1.2vw;
    font-family: 'Century Gothic';
    color: var(--green);
    width: 65%;
    height: 3vw;
    transition: 0.05s ease;
    cursor: pointer;
    border: none;
}

section.list button:hover {
    background-color: rgb(231, 231, 231);
}

section.list span {
    font-family: 'Century Gothic';
    height: 3vw;
    padding-left: 1vw;
    padding-right: 2vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5vw;
    font-size: 1vw;
    color: var(--green);
    background-color: rgb(228, 228, 228);
    position: relative;
    pointer-events: none;
}

section.list span::after {
    pointer-events: all;
    cursor: pointer;
    content: "";
    height: 0.8vw;
    background-color: var(--green);
    width: 0.1vw;
    position: absolute;
    right: 1vw;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
}

section.list span::before {
    pointer-events: all;
    cursor: pointer;
    content: "";
    height: 0.8vw;
    background-color: var(--green);
    width: 0.1vw;
    position: absolute;
    right: 1vw;
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
}


.filter-list-title {
    color: var(--black);
    width: 100%;
    font-size: 1vw;
    padding-top: 1vw;
}

.filter {
    cursor: pointer;
    color: rgb(28, 28, 28);
    padding: 1vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: rgba(231, 231, 231, 0.508);
    width: 9.6vw;
    gap: 1vw;
}



/* CHECKBOX */
.checkbox-wrapper-18 .round {
    position: relative;
    cursor: pointer;
}

.checkbox-wrapper-18 .round label {
    background-color: #fff;
    border: 0.1vW solid var(--green);
    border-radius: 50%;
    height: 1.3vw;
    width: 1.3vw;
    display: block;
    cursor: pointer;
}

.checkbox-wrapper-18 .round label:after {
    border: 0.13vw solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 0.3vw;
    left: 0.25vw;
    opacity: 0;
    position: absolute;
    top: 0.35vw;
    transform: rotate(-45deg);
    width: 0.6vw;
    transition: 0.1s ease;
}

.checkbox-wrapper-18 .round input[type="checkbox"] {
    visibility: hidden;
    display: none;

    opacity: 0;
}

.checkbox-wrapper-18 .round input[type="checkbox"]:checked+label {
    background-color: var(--green);
    border: none;
}

.checkbox-wrapper-18 .round input[type="checkbox"]:checked+label:after {
    opacity: 1;
}

.checkbox-label {
    cursor: pointer;
    font-family: 'Century Gothic';
    color: var(--green);
    font-size: 1vw;
}

@media screen and (max-width: 100vh) {
    .container-scroller {
        padding: 2vw;

        border: none;
        margin: none;
    }

    h5 {
        font-size: 5vw;
        margin-bottom: 1vw;
    }

    section label,
    section.list button {
        height: 8vw;
        font-size: 3vw;
    }

    section input {
        height: 8vw;
        font-size: 3vw;
        font-family: 'Century Gothic';
        color: var(--green);
        width: 65%;
        background-color: rgba(231, 231, 231, 0.508);
        border: none;
    }

    .container {
        gap: 3vw;
    }

    .checkbox-label {
        font-size: 3vw;
    }

    .filter {
        height: 8vw;
        width: auto;
        padding: 0 2vw;
    }

    .checkbox-wrapper-18 .round label {
        height: 2.5vw;
        width: 2.5vw;
    }

    section.list span {

        height: 7vw;
        padding-right: 5vw;
        font-size: 3vw;
    }

    .filter-list-title {
        font-size: 2.5vw;
    }

    section.list span::before,
    section.list span::after {
        height: 2vw;
        right: 2.5vw;
    }


}
</style>
