<template>
    <genericTextInput v-model="model" :placeholder="placeholder" isRequired>
        <template #icon>
            <img src="@/assets/image/forms/form-edit-header-label.svg" alt="🔑" />
        </template>
    </genericTextInput>
</template>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<script setup>
import { defineProps, defineModel } from 'vue';
import genericTextInput from '@/components/map/topTools/forms/customElements/commonElements/genericTextInput.vue'

const model = defineModel()

defineProps({
    placeholder: String,
    isRequired: Boolean,
});

</script>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<style scoped></style>
