<template>
  <div :class="{ showSuggestions: showSuggestions }" class="search-bar">
    <div class="container">
      <img src="@/assets/home/search.png" />
      <input ref="input" v-model="userInput" @focusin="showSuggestions = true" @focusout="showSuggestions = false"
        placeholder="Search for species..." type="text" />
      <button @click="handleSuggestionClick(null)">Search</button>
    </div>
    <div v-if="showSuggestions && suggestions.length > 0" class="suggestions-container">
      <p @mousedown="handleSuggestionClick(suggestion)" class="suggestion" v-for="(suggestion, key) in suggestions"
        :key="key">
        {{ suggestion.title }}
        <span class="add"></span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInput: "",
      showSuggestions: false,
      defaultSuggestions: [
        { title: "Plants", content: "plant", key: "type" },
        { title: "Animals", content: "animal", key: "type" },
        { title: "Insects", content: "insect", key: "type" },
      ],
    };
  },

  computed: {
    suggestions() {
      let ret = [];
      this.defaultSuggestions.forEach((element) => {
        ret.push(element);
      });
      this.$store.state.species.forEach((element) => {
        //If pour éviter les doublons, a changer peut être
        if (!ret.find(e => e.title == element["Nom scientifique"])) ret.push({
          title: element["Nom scientifique"],
          key: "predictedEspece",
        });

      });
      ret = ret.filter((e) =>
        e.title.toLowerCase().includes(this.userInput.toLowerCase())
      );
      return ret;
    },
  },
  methods: {
    focus() {
      this.userInput = "";
      this.$refs.input.focus();
    },
    handleSuggestionClick(suggestion) {
      if (suggestion != null)
        this.userInput = suggestion.content
          ? suggestion.content
          : suggestion.title;
      else {
        //verifier si c'est dans defaultSuggestions
        let defaultSuggestion = this.defaultSuggestions.find(
          (e) => e.title == this.userInput
        );
        if (!defaultSuggestion)
          defaultSuggestion = this.defaultSuggestions.find(
            (e) => e.content == this.userInput
          );
        if (defaultSuggestion) {
          suggestion = { key: defaultSuggestion.key };
          this.userInput = defaultSuggestion.content;
        } else suggestion = { key: "predictedEspece" };
      }
      this.$emit("setFilters", "observations", {
        [suggestion.key]: this.userInput,
      });

    },
  },
};
</script>

<style scoped>
.search-bar {
  position: absolute;
  top: 2vw;
  right: 2vw;
  width: 45%;
  box-shadow: none;
  z-index: 6;
}

.search-bar.showSuggestions {
  z-index: 99;
}

.container {
  height: 100%;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0.1vw 0.3vw;
  overflow: hidden;
  border-radius: 3vw;
}

.container img {
  pointer-events: none;
}

.suggestions-container {
  padding: 1vw 0;
  border-radius: 0.5vw;
  position: absolute;
  overflow: auto;
  top: 140%;
  left: 0;
  width: 90%;
  height: fit-content;
  max-height: 30vw;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0.1vw 0.3vw;
}

.suggestion {
  color: var(--black);
  font-family: "Century Gothic";
  position: relative;
  font-size: 1.2vw;
  width: 95%;
  margin: 1vw auto;
  cursor: pointer;
  border-top: 0.1vw solid rgba(211, 211, 211, 0.623);
  border-bottom: 0.1vw solid rgba(211, 211, 211, 0.623);
  padding: 1vw;
  padding-right: 4vw;
}

.suggestion .add {
  width: 3vw;
  background-color: rgba(215, 215, 215, 0.696);
  opacity: 0.5;
  height: 3vw;
  position: absolute;
  right: 0.5vw;
  top: 50%;
  transform: translateY(-50%);
}

.suggestion .add::after,
.suggestion .add::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(90deg);
  background-color: rgb(0, 0, 0);
  width: 0.1vw;
  height: 40%;
}

.suggestion .add::before {
  transform: translateX(-50%) translateY(-50%) rotate(0deg);
}

.suggestion .add:hover {
  opacity: 1;
}

.suggestion:hover {
  border-color: rgba(72, 72, 72, 0.623);
}

@media screen and (max-width: 100vh) {
  .search-bar {
    top: 6vw;
    width: 95%;
    right: 2.5%;
    height: 12vw;

  }

  .container {
    border-radius: 15vw;
  }

  .search-bar button {
    width: 16vw;
    font-size: 2.5vw;
  }

  .search-bar input {
    font-size: 3vw;
    padding-left: 11vw;
    padding-right: 17vw;
  }

  .search-bar img {
    left: 3vw;
    width: 6vw;
  }

  .suggestions-container {
    width: 100%;
    max-height: 60vh;
  }

  .suggestion {
    font-size: 3vw;
    width: 90%;
    margin: 2vw auto;
    padding: 2vw;
    padding-right: 10vw;
  }

  .suggestion .add {
    width: 6vw;
    height: 6vw;
  }
}
</style>