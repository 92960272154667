<template>
  <h3>Ongoing actions</h3>
  <span id="no-result-container">
    <img src="@/assets/map/in-development.png">
    In development...
  </span>
</template>

<script>
export default {

}
</script>

<style scoped>
h3 {
  font-family: "Century Gothic";
  font-size: 3vh;
  padding: 1vh;
  text-align: center;
  color: var(--black);
  border-radius: 5vh;
}


#no-result-container {
  position: absolute;
  flex-direction: column;
  height: 5vh;
  font-size: 2.5vh;
  align-items: center;
  justify-content: center;
  left: 50%;
  top: 55%;
  display: flex;
  gap: 1vh;
  opacity: 0.6;
  transform: translateX(-50%) translateY(-50%);
  font-family: 'Century Gothic';
}

#no-result-container img {
  width: 7vh;
}
</style>