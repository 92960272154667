<template>
    <nav ref="nav" :class="{ pulled: pulled || !pull, fullopacity: fullOpacity }" :style="{ height: height + 'vw' }">
        <routesHandler></routesHandler>
        <router-link id="logo" :to="'/home/' + $store.state.activeAirportKey">
            <img src="@/assets/logo.webp" />
        </router-link>
        <span @click="phoneLinksPulled = !phoneLinksPulled" id="burger-menu">
            <img src="@/assets/navbar/burger.png">
        </span>
        <div class="content">
            <div class="top">
                <p>{{ activeAirport.name }}</p>
                <div class="right">
                    <router-link :to="'/login/' + $store.state.activeAirportKey">
                        <img src="@/assets/navbar/account.png" />
                    </router-link>
                    <a v-if="this.$store.state.logged" @click="handleLogout">
                        <img src="@/assets/navbar/logout.png" />
                    </a>
                </div>
            </div>
            <div class="links" :class="{ pulled: phoneLinksPulled }">
                <router-link class="btn" :to="'/map/' + $store.state.activeAirportKey">Map</router-link>
                <span @click="airportsPulled = !airportsPulled" @mouseleave="airportsPulled = false" class="btn">Airport
                    <img src="@/assets/navbar/arrow-down.png">
                    <ul :class="{ pulled: airportsPulled }" class="link-list airports">
                        <router-link v-for="(airport, key) in airports" :key="key" :to="actualPageRoute + key">
                            <li>
                                {{ airport.name }}
                            </li>
                        </router-link>
                    </ul>
                </span>
            </div>
        </div>
    </nav>
</template>

<script>
import routesHandler from './routesHandler.vue';
import { auth } from '@/app/firebase';
import { signOut } from 'firebase/auth';
export default {
    components: {
        routesHandler
    },
    props: {
        pull: Boolean,
        navStyleData: Object
    },
    data() {
        return {
            height: 30, //vw
            pulled: false,
            fullOpacity: true,
            actualPageRoute: null,
            phoneLinksPulled: false,
            airportsPulled: false,
        };
    },
    computed: {
        activeAirport() {
            return this.$store.state.activeAirport;
        },
        airports() {
            return this.$store.state.airports;
        },
    },
    mounted() {

        this.actualPageRoute = this.$route.fullPath.replace(
            this.$store.state.activeAirportKey,
            ""
        );
        this.handleWindowResize()
        window.addEventListener("resize", this.handleWindowResize)
        window.addEventListener("scroll", this.handleWindowScroll);
        this.$refs.nav.addEventListener("mouseenter", this.handleNavMouseEnter);
        this.$refs.nav.addEventListener("mouseleave", this.handleNavMouseLeave);
    },
    methods: {
        handleWindowResize() {
            const ratio = window.innerWidth / window.innerHeight

            if (ratio < 1) this.height = 30
            else this.height = 10
            if (!(this.$props.navStyleData == undefined)) this.$props.navStyleData.height = this.height
        },
        handleWindowScroll() {
            if (window.scrollY > 30 && this.$props.pull !== true) {
                this.fullOpacity = false;
            } else {
                this.fullOpacity = true;
            }
        },
        handleLogout() {
            signOut(auth).then(() => {
                this.$store.commit('setLogged', false)
                this.$router.push('/login/' + this.$store.state.activeAirportKey)
            })
        },
        handleNavMouseEnter() {
            this.fullOpacity = true;
            this.pulled = true;
        },
        handleNavMouseLeave() {
            if (window.scrollY > 30 && this.$props.pull !== true)
                this.fullOpacity = false;
            if (this.$props.pull) this.pulled = false;
        },
        getHeight() {
            return this.height;
        },
        setPulled(bool) {
            this.pulled = bool;
            if (!bool) this.phoneLinksPulled = false
        },
    },
};
</script>

<style scoped>
nav {
    position: fixed;
    transition: 0.15s ease;
    transform: translateY(-90%);
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    box-shadow: rgba(81, 81, 81, 0.081) 0px 0.2vw 0.2vw 0px;
    padding: 0 10vw;
    z-index: 999;
    border-bottom: 0.1vw solid rgba(0, 0, 0, 0.114);
    background-color: rgb(255, 255, 255);
    opacity: 0.4;
}

nav.pulled {
    transform: translateY(0);
}

nav.fullopacity {
    opacity: 1;
}

nav img {
    pointer-events: none;
    width: 100%;
}

#burger-menu {
    z-index: 3;
    width: 6vw;
    position: absolute;
    left: 3vw;
    top: 50%;
    display: none;
    cursor: pointer;
}

#burger-menu img {
    width: 100%;
    opacity: 0.8;

}

#logo img {

    width: 10vw;
}

.content {
    width: 60%;
    display: flex;
    align-content: center;
    justify-content: space-between;
    height: 100%;
    flex-direction: column;
}

.top {
    width: 100%;
    height: 30%;
    border-bottom: 0.1vw solid rgb(149, 149, 149);
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-right: 0.5vw;
}

.top p {
    color: rgba(108, 108, 108, 0.807);
    font-size: 0.8vw;
    padding-bottom: 0.5vw;
}

.top .right {
    display: flex;
    gap: 0.2vw;
    height: 100%;
}

.top .right a {
    width: auto;
    height: 100%;
    opacity: 0.7;
    cursor: pointer;
    transition: 0.1s ease;
}

.top a:hover {
    opacity: 1;
}

.top .right a img {
    height: 100%;
    padding-top: 0.8vw;
    padding-bottom: 0.2vw;
    display: block;
}

.links {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 4vw;
    height: 70%;
}

.links .btn {
    cursor: pointer;
    padding-bottom: 2vw;
    transition: 0.1s ease;
    text-decoration: none;
    font-size: 1.2vw;
    color: var(--green);
    position: relative;
    display: flex;
    align-items: center;
    gap: 1vw;
}

.links .btn img {
    width: 1.5vw;
}

.links .btn:hover {
    color: var(--orange);
}

.links .btn:hover::after {
    opacity: 1;
}

.links .btn::after {
    transition: 0.15s ease;
    opacity: 0;
    content: "";
    position: absolute;
    width: 100%;
    height: 7%;
    background-color: var(--orange);
    bottom: 0;
    left: 0;
}

.link-list.pulled.pulled {
    opacity: 1;
    pointer-events: all;
}


.link-list {
    transition: 0.2s ease;
    position: absolute;
    width: 18vw;
    top: 100%;
    height: auto;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: -50%;
    opacity: 0;
    pointer-events: none;
}

.link-list li {
    transition: 0.1s ease;
    color: var(--green);
    list-style: none;
    padding: 1vw;
    width: 100%;
    margin: auto;
    border-bottom: 0.1vw rgb(211, 211, 211) solid;
}

.link-list li:hover {
    background-color: var(--orange);
    opacity: 0.9;
    color: rgb(255, 255, 255);
}

.link-list a {
    color: inherit;
    text-decoration: none;
}

@media screen and (max-width: 100vh) {
    #burger-menu {
        display: block;
    }

    nav {
        padding: 0;
    }

    .content {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .top {
        position: absolute;
        width: 100%;
        height: 8vw;
        top: 0;
        left: 0;
    }

    .top p {
        font-size: 2.5vw;
        display: flex;
        padding: 0 2vw;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    .top .right a img {
        padding: 1vw;
    }

    #logo img {
        margin-top: 8vw;
        width: 30vw;
    }

    .links {

        transition: 0.2s ease;
        transform: translateX(-100%);
        position: absolute;
        top: 100%;
        width: 100%;
        background-color: white;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: fit-content;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 0.1vw 0.3vw;
        padding: 3vw;

    }

    .links.pulled {
        transform: translateX(0);
    }

    .links .btn {
        border-bottom: 0.1vw solid rgba(0, 0, 0, 0.132);
        width: 100%;
        text-align: left;
        justify-content: space-between;
        font-size: 3.5vw;
    }

    .links .btn img {
        width: 4vw;
    }

    .link-list {
        transition: 0.2s ease;
        position: absolute;
        width: 100%;
        top: 100%;
        height: auto;
        background-color: rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: 0;
        opacity: 0;
        pointer-events: none;
    }

    .link-list li {
        padding: 2vw;
        font-size: 3vw;
    }
}
</style>
