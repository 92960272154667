<template>
    <div class="manage-container">
        <loadingComp ref="loading"></loadingComp>
        <popupComp @response="handlePopupResponse" ref="popup" />
        <div class="search-bar fully">
            <div class="search-bar-container">
                <img src="@/assets/home/search.png" />
                <input v-model="userInput" ref="input" placeholder="Search for account..." type="text" />
            </div>
            <button @click="handleAdd" id="add">NEW</button>
        </div>



        <div class="row" style="background-color: rgba(0, 0, 0, 0.027)">
            <section class="results-count">
                <label>{{ filteredUsers.length }} results found</label>
            </section>
        </div>

        <template v-if="profileToEdit == null">
            <div class="content-scroller">
                <div v-for="(user, key) in filteredUsers" :key="key" class="row">
                    <section>
                        <label>Name</label>
                        <p>{{ user.name }}</p>
                    </section>
                    <section>
                        <label>Forename</label>
                        <p>{{ user.forename }}</p>
                    </section>

                    <div class="btns">
                        <button @click="profileToEdit = user; newProfile = false" class="edit">Edit</button>
                        <button @click="handleDelete(user)" class="delete">Delete</button>
                    </div>
                </div>
            </div>

        </template>

        <EditComp :title="title" :rows="userRows" @update="handleUserUpdate" @cancel="profileToEdit = null"
            :newObj="newProfile" @new="handleNewUser" :data="profileToEdit" v-if="profileToEdit != null" />

    </div>
</template>

<script>
import popupComp from '@/components/popupComp.vue'
import EditComp from './EditComp.vue'
import loadingComp from '@/components/loadingComp.vue'

export default {
    components: {
        EditComp,
        popupComp,
        loadingComp
    },

    async mounted() {
        this.$refs.loading.open("Pulling users")
        await this.$store.dispatch("getAllRoles")
        await this.$store.dispatch("getAllAccounts")
        this.userRows = [
            {
                title: 'Name',
                key: 'name',
                type: 'text'
            },
            {
                title: 'Forename',
                key: 'forename',
                type: "text"
            },
            {
                title: "Roles",
                key: "roles",
                type: "list",
                options: this.$store.state.roles,
                optionKey: "name",
                comparisonKey: "id"
            },
            {
                title: 'Email',
                key: 'mail',
                onlyCreate: true,
                type: 'mail'
            },
            {
                title: 'Password',
                key: 'password',
                onlyCreate: true,
                type: 'password'
            }
        ]
        this.$refs.loading.close()
    },
    methods: {
        handleNewUser(data) {
            //TODO : do it from chosen roles
            data.roles = data.roles.map(e => e.id)
            this.$refs.loading.open("Creating user")

            fetch(process.env.VUE_APP_BACKEND_V2_URL + "/newUser",
                {
                    method: "POST",
                    body: JSON.stringify(data)
                }
            ).then(res => {
                res.json().then(json => {
                    //if no error
                    if (!json.error) {
                        data.password = undefined
                        this.$store.commit("addAccount", json)
                        this.profileToEdit = null
                    }
                    this.$refs.loading.close()
                })
            })
        },
        handleDelete(user) {
            this.$refs.popup.show("are you sure you want to delete this user ?", "dilem-revert", 'delete', user)
        },
        handlePopupResponse(action, response, data) {

            if (action == 'delete' && response == 'ok') {
                this.$refs.loading.open("Deleting user")
                fetch(process.env.VUE_APP_BACKEND_V2_URL + "/deleteUser/" + data.id,
                    {
                        method: "DELETE",
                    }).then(res => {
                        res.json().then(json => {
                            if (!json.error) {
                                this.$store.state.accounts.splice(this.$store.state.accounts.findIndex(e => e.email == data.email), 1)
                            }
                            this.$refs.loading.close()
                        })
                    })
            }

        },
        handleAdd() {
            const newUser = {
                name: "",
                forename: "",
                roles: [],
                email: "",
                password: ""
            }
            this.newProfile = true
            this.profileToEdit = newUser
        },
        handleUserUpdate(data) {
            let body = JSON.parse(JSON.stringify(data))
            let userIndex = this.$store.state.accounts.findIndex(e => e.mail === data.mail)

            let oldRoleIds = this.$store.state.accounts[userIndex].roles.map(e => e.id)
            let newRoleIds = body.roles.map(e => e.id)

            const removed = oldRoleIds.filter(item => !newRoleIds.includes(item));
            const added = newRoleIds.filter(item => !oldRoleIds.includes(item));

            delete (body.roles)

            const rolesChanges = {
                removed: removed,
                added: added,
            };
            body.rolesChanges = rolesChanges


            this.$refs.loading.open("Updating user")

            fetch(process.env.VUE_APP_BACKEND_V2_URL + "/updateUser/" + data.id,
                {
                    method: "POST",
                    body: JSON.stringify(body),
                }
            ).then(res => {
                res.json().then(json => {
                    if (!json.error) this.$store.state.accounts[userIndex] = data;

                    this.$refs.loading.close()
                    this.profileToEdit = null
                })
            })
        }
    },

    data() {
        return {
            title: "Account",
            userInput: "",
            profileToEdit: null,
            newProfile: false,
            userRows: [],
        }
    },

    computed: {
        filteredUsers() {
            if (this.$store.state.accounts == null) return []
            if (this.userInput == "") return this.$store.state.accounts
            const userInputLower = this.userInput.toLowerCase()
            return this.$store.state.accounts.filter(e => e.name.toLowerCase().includes(userInputLower) ||
                e.forename.toLowerCase().includes(userInputLower))
        }
    }
}
</script>

<style scoped>
.results-count {
    color: #333;
    gap: 0.5vw;
}
</style>