<template>
    <div class="manage-container">
        <loadingComp ref="loading"></loadingComp>
        <popupComp @response="handlePopupResponse" ref="popup" />
        <div class="search-bar fully">
            <div class="search-bar-container">
                <img src="@/assets/home/search.png" />
                <input v-model="userInput" ref="input" placeholder="Search for Inventories..." type="text" />
            </div>
            <button @click="handleAdd" id="add">NEW</button>
        </div>

        <template v-if="InventoryToEdit == null">
            <div class="content-scroller">
                <div v-for="(inventory, key) in filteredInventories" :key="key" class="row">
                    <section>
                        <label>Code</label>
                        <p>{{ inventory.code }}</p>
                    </section>
                    <section>
                        <label>Type</label>
                        <p>{{ inventory.type }}</p>
                    </section>
    
                    <div class="btns">
                        <button @click="newInventory = false; InventoryToEdit = inventory; InventoryToEditIndex = key"
                            class="edit">Edit</button>
                        <button @click="handleDelete(inventory)" class="delete">Delete</button>
                    </div>
                </div>
            </div>
            
        </template>
        <EditComp :title="title" :rows="inventoryRows" @update="handleUpdateInventory" @cancel="InventoryToEdit = null"
            :newObj="newInventory" @new="handleNewInventory" :data="InventoryToEdit" v-if="InventoryToEdit != null" />
    </div>
</template>

<script>
import EditComp from './EditComp.vue'
import popupComp from '@/components/popupComp.vue'
import loadingComp from '@/components/loadingComp.vue'

export default {
    components: {
        EditComp,
        popupComp,
        loadingComp
    },
    methods: {
        handleDelete(inventory) {
            inventory
            this.$refs.popup.show("are you sure you want to delete this inventory ?", "dilem-revert", 'delete', inventory)
        },
        handleAdd() {
            this.newInventory = true;
            this.InventoryToEdit = {
                permanent: true,
                type: "daily",
                membres: [],
            }
        },
        handlePopupResponse(action, response, data)
        {
            if (action == 'delete' && response == 'ok') {
                this.$refs.loading.open("Deleting inventory")
                fetch(process.env.VUE_APP_BACKEND_V2_URL + "/deleteAirportInventoryByCode/cdg/" + data.code,
                    {
                        method: "DELETE",
                    }).then(res => {
                        res.json().then(json => {
                            if (!json.error) {
                                this.$store.state.inventories.splice(this.$store.state.inventories.findIndex(e => e.code == data.code), 1)
                            }
                            this.$refs.loading.close()
                        })
                    })
            }
        },
        handleNewInventory(data) {
            this.$refs.loading.open("Adding new inventory")
            fetch(process.env.VUE_APP_BACKEND_V2_URL + "/newAirportInventory/cdg", 
            {method : "POST", body : JSON.stringify(data)}).then(res=>{
                res.json().then(json=>{
                    if(!json.error){
                        this.$store.commit("addInventory", data)
                    }
                    this.InventoryToEdit = null
                    this.$refs.loading.close()
                })
            })
        },
        handleUpdateInventory(data) {     
            this.$refs.loading.open("Updating inventory")
            fetch(process.env.VUE_APP_BACKEND_V2_URL + "/updateAirportInventoryByCode/cdg/" + this.InventoryToEdit.code, 
            {method : "POST", body : JSON.stringify(data)}).then(res=>{
                res.json().then(json=>{
                    if(!json.error){
                        this.$store.state.inventories[this.InventoryToEditIndex] = data
                    }
                    this.InventoryToEdit = null
                    this.$refs.loading.close()
                })
            })
        }
    },
    async mounted() {
        this.$refs.loading.open("Fetching inventorys")
        await this.$store.dispatch("getAllAccounts")
        await this.$store.dispatch("getAirportInventorys")
        console.log("finishde")
        this.$refs.loading.close()
    },

    computed:
    {
        filteredInventories() {
            return this.$store.state.inventories
        }
    },

    data() {
        return {
            title: 'Inventory',
            userInput: '',
            newInventory: false,
            InventoryToEdit: null,
            InventoryToEditIndex: null,
            inventoryRows: [
                {
                    title: 'Code',
                    type: 'text',
                    key: 'code',
                    placeholder: "Enter code here..."
                },
                {
                    title: 'Permanent',
                    key: 'permanent',
                    type: 'choice',
                    choices: [true, false],
                },
                {
                    title: 'Type',
                    key: 'type',
                    type: 'choice',
                    choices: ['daily', 'protocol'],
                },
                {
                    title: 'Start date',
                    key: 'date_début',
                    type: 'date',
                },
                {
                    title: 'End date',
                    key: 'date_fin',
                    type: 'date',
                },
                {
                    title: 'Team',
                    key: 'membres',
                    type: 'list',
                    options: this.$store.state.accounts.map(account => account.mail),
                }
            ]
        }
    }

}
</script>

<style></style>