import { createApp } from 'vue'
import App from './App.vue'

import { plugin as Slicksort } from 'vue-slicksort';

import Router from './app/router'
import store from './app/store'

// ROUTER END

createApp(App)
    .use(Router)
    .use(store)
    .use(Slicksort)
    .mount('#app')
