<!-- Right panel component of the forms management, this panel is used to manage the keys of the forms -->

<!-- ! This could probably be refactored into a reusable component along with ManageForms -->

<template>
    <div v-show="selectedKeyToEdit === null" class="manage-container">

        <div class="search-bar" :class="{ '--full-width': !userPermissions.includes('CK') }">
            <div class="search-bar-container">
                <img src="@/assets/home/search.png" />
                <input ref="input" v-model="keyUserSearchInput" placeholder="Search by code" />
            </div>
        </div>
        <!-- TODO @click="handleNewFormCreation" -->
        <button v-if="userPermissions.includes('CK')" @click="handleKeyCreation" id="add">NEW</button>
        <div class="row --filter-bar">

            <div></div>

            <!-- Number of results found -->
            <section class="results-count">
                <label>{{ filteredKeys.length }} results found</label>
            </section>

            <div class="--group">
                <!-- Dropdown to select sorting -->
                <select v-model="keySelectedSortingMode" id="sort-by">
                    <option v-for="mode in keySortingModes" :key="mode.key" :value="mode.key">
                        {{ mode.title }}
                    </option>
                </select>

                <!-- Refresh button -->
                <button @click="refreshKeys" class="refresh">
                    <img src="@/assets/image/forms/manage-forms-refresh-icon.svg" />
                </button>
            </div>
        </div>

        <!-- Modal that appears when deleting a form -->
        <genericFormModal v-if="showModal" :closeModal="closeModal" @buttonClick="handleKeyDeletion"
            modalTitle="Deletion confirmation" modalMessage="Are you sure you want to delete this key?"
            rightButtonText="Delete" :rightButtonStyle="{ backgroundColor: 'var(--red-light)' }" />

        <template v-if="selectedKeyToEdit === null">
            <div v-for="(key, index) in filteredKeys" :key="index" class="row">

                <div class="row-content">
                    <template v-for="(keyRow) in keyItemRow" :key="keyRow.key">
                        <div class="row-content-item">
                            <section>
                                <label>{{ keyRow.title }}</label>
                                <p>{{ key[keyRow.key] }}</p>
                            </section>
                        </div>
                    </template>
                </div>

                <div class="row-btns btns" v-if="userPermissions.includes('MK') || userPermissions.includes('DK')">
                    <button v-if="userPermissions.includes('MK')" @click="openDropdown(key.code)" class="edit"
                        ref="dropdown">
                        <img src="@/assets/image/forms/form-comp-edit-form.svg" />
                        <div v-if="openedDropdown === key.code" class="dropdown"
                            :class="{ '--last-row': isDropdownOpenedInLastRow }">
                            <button @click.stop="handleDropdownEdit(key)">Edit</button>
                            <button @click.stop="handleDropdownDuplicate(key)">Duplicate</button>
                        </div>
                    </button>
                    <button v-if="userPermissions.includes('DK')" @click="handleDelete(key)" class="delete">
                        <img src="@/assets/image/forms/form-field-trash-bin-icon.svg" />
                    </button>
                </div>
            </div>
        </template>
    </div>

    <!-- Key editing -->
    <div v-if="selectedKeyToEdit !== null">
        <h4>Note: This is still in development</h4>
        <basicTextInputWithImage v-model="selectedKeyToEdit.label" placeholder="Label">
            <template #icon>
                🏷️
            </template>
        </basicTextInputWithImage>
        <basicTextInputWithImage v-model="selectedKeyToEdit.code" placeholder="Code">
            <template #icon>
                <img src="@/assets/image/forms/form-input-key-icon.svg" alt="🔑" />
            </template>
        </basicTextInputWithImage>
        <button @click="handleKeyModification">Submit</button>
        <button @click="selectedKeyToEdit = null">Cancel</button>
    </div>
</template>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<script>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import genericFormModal from '@/components/map/topTools/forms/customElements/genericFormModal.vue';
import basicTextInputWithImage from './customElements/basicTextInputWithImage.vue';

export default {
    // ? - - - Components - - - - -
    components: {
        genericFormModal, basicTextInputWithImage
    },

    // ? - - - Emits - - - - - - - -
    emits: ['lock-categories'],

    // ? - - - Props - - - - - - - -
    props: {
        userPermissions: Array,
    },

    // ? - - - Setup - - - - - - - -
    setup(_, { emit }) {
        const store = useStore();
        const keysData = ref([]);
        const selectedKeyToEdit = ref(null);

        watch(selectedKeyToEdit, (newValue) => {
            emit('lock-categories', newValue !== null);
        });

        keysData.value = store.state.form.formKeys;

        return {
            keysData, selectedKeyToEdit
        };
    },

    // ? - - - Data - - - - - - - -
    data() {
        return {
            keyUserSearchInput: '',
            keySelectedSortingMode: 'code',
            keySortingModes: [
                {
                    title: 'Sort by Code',
                    key: 'code'
                },
                {
                    title: 'Sort by Label',
                    key: 'label'
                },
            ],
            keyItemRow: [
                {
                    title: "Label",
                    key: "label",
                    type: "text"
                },
                {
                    title: "Code",
                    key: "code",
                    type: "text"
                },
            ],
            showModal: false,
            openedDropdown: null,
            pendingKeyDeletion: null
        }
    },

    // ? - - - Computed - - - - - -
    computed: {
        filteredKeys() {
            return this.keysData.length > 0
                ? this.keysData
                    // Filter empty dictionary keys
                    .filter(key => Object.keys(key).length > 0)
                    // Filter by search input
                    .filter(key => (key.code || '').toLowerCase().includes((this.keyUserSearchInput || '').toLowerCase()))
                    // Sort by selected mode
                    .sort((a, b) => {
                        return a[this.keySelectedSortingMode].localeCompare(b[this.keySelectedSortingMode]);
                    })
                : [];
        },
        isDropdownOpenedInLastRow() {
            return this.openedDropdown && this.openedDropdown === this.filteredKeys[this.filteredKeys.length - 1].code;
        }
    },

    // ? - - - Methods - - - - - - -
    methods: {
        openModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
        openDropdown(code) {
            this.openedDropdown = code;
        },
        closeDropdown() {
            this.openedDropdown = null;
        },
        handleClickOutsideDropdown(event) {
            const clickedOutside =
                !(this.$refs.dropdown && (Array.isArray(this.$refs.dropdown)
                    ? this.$refs.dropdown.some(dropdownElement => dropdownElement.contains(event.target))
                    : this.$refs.dropdown.contains(event.target)));

            if (clickedOutside && this.openedDropdown !== null) {
                this.closeDropdown();
            }
        },
        refreshKeys() {
            const refreshButton = document.querySelector('.refresh');
            refreshButton.classList.add('refresh--animate');
            setTimeout(() => {
                refreshButton.classList.remove('refresh--animate');
            }, 300);
            this.$store.dispatch('refreshFormKeys')
                .then(() => {
                    this.keysData = this.$store.state.form.formKeys;
                });
        },
        handleKeyCreation() {
            this.selectedKeyToEdit = {
                new: true,
                code: '',
                label: ''
            };
        },
        handleDropdownEdit(key) {
            this.selectedKeyToEdit = key;
            this.closeDropdown();
        },
        handleDropdownDuplicate(key) {
            this.selectedKeyToEdit = {
                new: true,
                code: key.code + '_copy',
                label: key.label + '_copy'
            };
            this.closeDropdown();
        },
        async fetchKeysIDs() {
            const url = `${process.env.VUE_APP_BACKEND_V2_URL}/getFormKeysCodeOnly`;
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            };

            const data = await this.fetchData(url, options);
            return data;
        },
        async isIdUnique(id = this.selectedKeyToEdit.code) {
            const keysIDs = await this.fetchKeysIDs();
            return !keysIDs.includes(id);
        },
        isEmptyOrWhitespace(str) {
            // FIXME: Duplicate function, move to store
            return str === '' ? true : str.trim() === '';
        },
        async handleKeyModification() {

            if (this.selectedKeyToEdit.new && !await this.isIdUnique()) {
                alert("A key with this code already exists");
                return;
            }

            // Prevent empty or whitespace keys & labels
            if (this.isEmptyOrWhitespace(this.selectedKeyToEdit.code) || this.isEmptyOrWhitespace(this.selectedKeyToEdit.label)) {
                alert("Key and label cannot be empty or whitespace");
                return;
            }

            // TODO: if label already exists, show modal to confirm 


            if (this.selectedKeyToEdit.new) {
                fetch(process.env.VUE_APP_BACKEND_V2_URL + '/newFormKey', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(this.selectedKeyToEdit)
                })
                    .then(response => response.json())
                    .then(() => {
                        alert("Key created successfully");
                        this.keysData
                            = this.$store.state.form.formKeys
                            = this.keysData.concat(this.selectedKeyToEdit);

                        this.selectedKeyToEdit = null;
                    });
            } else {
                fetch(process.env.VUE_APP_BACKEND_V2_URL + '/updateFormKey/' + this.selectedKeyToEdit.code, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(this.selectedKeyToEdit)
                })
                    .then(response => response.json())
                    .then(() => {
                        alert("Key updated successfully");
                        this.keysData
                            = this.$store.state.form.formKeys
                            = this.keysData.map(key => key.code === this.selectedKeyToEdit.code ? this.selectedKeyToEdit : key);

                        this.selectedKeyToEdit = null;
                    });
            }
        },
        handleDelete(key) {
            this.openModal();
            this.pendingKeyDeletion = key;
        },
        async handleKeyDeletion(button) {
            if (button === 'right') this.deleteKey();
            this.formPendingDeletionConfirmation = null;
            this.closeModal();
        },
        async fetchData(url, options) {
            const response = await fetch(url, options);
            const data = await response.json();
            return data;
        },
        async deleteKey() {
            const url = `${process.env.VUE_APP_BACKEND_V2_URL}/deleteFormKey/${this.pendingKeyDeletion.code}`;
            const options = {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
            };

            const data = await this.fetchData(url, options);
            alert(data.message || data.success || data.error);
            this.keysData
                = this.$store.state.form.formKeys
                = this.keysData.filter(key => key.code !== this.pendingKeyDeletion.code);

            this.pendingKeyDeletion = null;
            this.closeModal();
        }
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutsideDropdown);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutsideDropdown);
    },
}
</script>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<!-- TODO remove unused styling -->

<style scoped lang="scss">
/* Source: https://stackoverflow.com/questions/44793453/how-do-i-add-a-top-and-bottom-shadow-while-scrolling-but-only-when-needed */
.manage-container {
    background:
        /* What this does is cover the shadow when the content is at the top */
        linear-gradient(var(--white) 30%, rgba(255, 255, 255, 0)),
        /* Here is the cover at the bottom */
        linear-gradient(rgba(255, 255, 255, 0), var(--white) 70%) 0 100%,
        /* This is the shadow at the top, so 0% of the farthest-side */
        radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0)),
        /* And this is the shadow at the bottom, 100% farthest-side */
        radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0)) 0 100%;

    background-repeat: no-repeat;
    background-size: 100% 5vw, 100% 5vw, 100% 2vw, 100% 2vw;
    background-attachment: local, local, scroll, scroll;
}

.results-count {
    color: var(--black-light);
    gap: 0.5vw;
}

.search-bar {
    &.--full-width {
        width: 100%;
    }

    .search-bar-container img {
        pointer-events: none;
    }
}

.row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vw;
    max-height: 5vw;
    min-height: 5vw;

    .row-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &.expanded {
        background-color: var(--grey-light);

        max-height: 30vw;

        .row-content-item {

            section {
                p {
                    white-space: normal;
                    overflow: visible;
                    text-overflow: unset;
                    word-wrap: break-word;
                }
            }
        }
    }

    .row-content-item {

        section {

            p {
                text-align: center;

                /* Handle overflow */
                width: 18vw;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    #see-more {
        border: none;
        background-color: transparent;
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;

        width: 2vw;
        height: 2vw;
        left: -1.5vw;
        rotate: -5deg;
        transition: 0.2s ease;

        &:hover {
            rotate: 10deg;
            transition: 0.4s ease;
        }

        &.expanded {
            rotate: 15deg;

            &:hover {
                rotate: -5deg;
            }
        }
    }

    .row-btns button {
        border-radius: var(--medium-radius);
        transition: 0.2s ease;
        font-size: 1vw;
        width: 100%;

        img {
            display: flex;
            align-items: center;
            justify-content: center;

            pointer-events: none;

            width: 1.5vw;
            height: 1.5vw;
        }

        &.delete {
            /* Subtle indicator that this is a delete button */
            border-bottom: 0.2vw solid var(--red-light);

            &:hover {
                border-bottom-color: #de2222;
                transition: 0.2s ease;
            }
        }

        &.edit {
            /* Subtle indicator that this is an edit button */
            border-bottom: 0.2vw solid var(--grey-darker);

            &:hover {
                border-bottom-color: #787878;
                transition: 0.2s ease;
            }
        }
    }

    #see-more img {
        width: 1.5vw;
        height: 1.5vw;

        pointer-events: none;

        background-color: var(--grey-dark);
        border-radius: var(--small-radius);
    }

    &.--filter-bar {
        background-color: var(--grey-light);
        border-radius: var(--large-radius);

        min-height: 1vw;
        padding: 0.4vw;

        .--group {
            display: flex;
            gap: 0.5vw;
        }

        img {
            display: flex;
            align-items: center;
            justify-content: center;

            pointer-events: none;
            width: 1.5vw;
            height: 1.5vw;
        }

        button {
            display: flex;
            align-items: center;
            justify-content: center;

            background-color: var(--white);
            border: 0.1vw solid var(--grey-darker);
            border-radius: var(--medium-radius);

            padding: 0.3vw;
            opacity: 0.7;

            cursor: pointer;
        }

        select {
            background-color: var(--white);
            border: 0.1vw solid var(--grey-darker);
            border-radius: var(--medium-radius);

            padding: 0.3vw;
            cursor: pointer;

            opacity: 0.7;
            font-size: 1vw;
        }

        .filter-all {

            img {
                transition: 0.3s ease-in-out;
                rotate: -90deg;
            }

            &.--collapsed img {
                rotate: 90deg;
                transition: 0.3s ease-in-out;
            }
        }

        /* Animations & transitions */
        .refresh--animate img {
            transform: rotate(360deg);
            transition: 0.3s ease;
        }
    }
}

.dropdown {
    position: absolute;
    background-color: var(--grey-light);
    box-shadow: var(--medium-shadow);
    border: 0.01vw solid var(--grey);
    z-index: 1;
    border-radius: var(--small-radius);
    display: flex;
    flex-direction: column;
    gap: 0.1vw;
    padding: 0.2vw;

    &.--last-row {
        /* Make dropdown appear above the button */
        margin-top: -8.5vw;
    }
}

.dropdown button {
    color: var(--black-light);
    padding: 0.5vw;
    text-decoration: none;
    display: block;
    border: none;
    background: none;
    width: 100%;
    text-align: left;

    &:hover {
        background-color: var(--grey-dark);
        color: var(--black-light);

        transition: 0.2s ease;
    }
}
</style>