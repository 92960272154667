<template>
  <div class="alert">
    <img :style="{ backgroundColor: getAlertColor(data.priority) }" src="@/assets/map/alert-white.png">
    <span id="more-btn">Learn more</span>
    <div class="data">
      <section>
        <h6>type</h6>
        <p>{{ data.type }}</p>
      </section>
      <section>
        <h6>Last modified</h6>
        <p>{{ data['last modified'] }}</p>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    getAlertColor: Function
  },
  methods: {
  }
}
</script>

<style scoped>
.alert {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  height: 6vw;
  border: 0.1vw solid rgba(0, 0, 0, 0.134);
  padding: 1vw;
  border-radius: 1vw;
  overflow: hidden;
}

.alert img {
  height: 100%;
  overflow: visible;
  padding: 1vw;
  border-radius: 100%;
}

.data {
  width: 80%;
  display: flex;
  justify-content: space-around;
}


.data section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2vw;

}

.data section h6 {
  font-size: 1vw;
  color: var(--green);
}

.data section p {
  font-size: 0.8vw;
}

#more-btn {
  transition: 0.1s ease;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5vw;
  background-color: var(--green);
}

#more-btn:hover {
  opacity: 0.95;
}

@media screen and (max-width: 100vh) {
  .alert {
    height: 15vw;
    padding: 4vw;
    border-radius: 5vw;
  }

  .alert img {
    width: 8vw;
    padding: 1.5vw;
    height: 8vw;
  }

  .data section {
    width: 50%;
    justify-content: center;
  }

  .data section h6 {
    font-size: 2.5vw;
  }

  .data section p {
    padding: 1.5vw;
    font-size: 2.1vw;
    width: 100%;
    text-align: center;
  }
}
</style>