<template>
    <div v-if="userHasAdminPermission() && adminElementSelected != null" class="admin-container">
        <div class="left">
            <div class="scroller">
                <template v-for="(adminElement, key) in adminPermissions" :key="key">
                    <span v-if="userHasPermission(adminElement)" @click="adminElementSelected = key"
                        :class="{ selected: key == adminElementSelected }" class="admin-element">
                        {{ adminElement.title }}
                    </span>
                </template>
            </div>
        </div>
        <div class="right">
            <ManageAccounts v-if="adminPermissions[adminElementSelected].code == 'MA'" />
            <ManagePermissions v-else-if="adminPermissions[adminElementSelected].code == 'MP'" />
            <ManageRoles v-else-if="adminPermissions[adminElementSelected].code == 'MR'" />
            <ManageAlertRules v-else-if="adminPermissions[adminElementSelected].code == 'MAR'" />
            <ManageInventories v-else-if="adminPermissions[adminElementSelected].code == 'MI'" />
            <ManageSpecies v-else-if="adminPermissions[adminElementSelected].code == 'MS'"/>
            <span v-else id="no-result-container">
                <img src="@/assets/map/in-development.png">
                In development...
            </span>
        </div>
    </div>
    <div v-else>
        <span id="no-result-container">
            <img src="@/assets/image/forms/form-comp-access-denied.svg">
            You do not have the necessary permissions to access the form management section.
            If you believe this is an error, please reach out to your system administrator.
            Alternatively, you may want to verify your own assigned permissions.
        </span>
    </div>
</template>

<script>
import ManagePermissions from './administration/ManagePermissions.vue'
import ManageAccounts from './administration/ManageAccounts.vue'
import ManageRoles from './administration/ManageRoles.vue'
import ManageAlertRules from './administration/ManageAlertRules.vue'
import ManageSpecies from './administration/ManageSpecies.vue'
import ManageInventories from './administration/ManageInventories.vue'


export default {
    components: {
        ManageAccounts,
        ManageAlertRules,
        ManageRoles,
        ManagePermissions,
        ManageInventories,
        ManageSpecies
    },
    data() {
        return {
            adminElementSelected: null,
            adminPermissions: [
                { code: "MA", title: "Manage accounts" },
                { code: "MP", title: "Manage permissions" },
                { code: "MR", title: "Manage roles" },
                { code: "MAR", title: "Manage alert rules" },
                { code: "MI", title: "Manage inventories" },
                {code : "MS", title : "Manage species"}
            ],

        }
    },

    mounted() {
        // put the first admin permission selected

        for (let i = 0; i < this.adminPermissions.length; i++) {
            if (this.$store.state.user.permissions.find(e => e == this.adminPermissions[i].code)) {
                this.adminElementSelected = i
                break
            }
        }
    },

    methods: {
        userHasAdminPermission() {

            for (let i = 0; i < this.adminPermissions.length; i++) {
                if (this.$store.state.user.permissions.find(e => e == this.adminPermissions[i].code)) {
                    return true
                }

            }
            return false
        },
        userHasPermission(adminElement) {
            //IMPOSSIBLE ? if (!this.adminPermissions.includes(code)) return false
            if (this.$store.state.user.permissions == null) return true
            if (this.$store.state.user.permissions.find(e => e == adminElement.code)) return true
            return false
        },

    }
}
</script>

<style scoped>
@import url('@/assets/css/admin-manage-container.css');

.admin-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    position: relative;
    width: 100%;
    height: 100%;
}

.left {
    width: 30%;
    padding: 2vw;
    height: 92%;
    margin-top: 2%;
    position: relative;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.027);
    border-radius: 0.6vw;
}

.left .scroller {
    display: flex;
    flex-direction: column;
    gap: 1vw;
    width: 100%;
}

.right {
    width: 68%;
    height: 92%;
    margin-top: 2%;
    position: relative;
}


.admin-element {
    border-radius: 0.5vw;
    display: block;
    font-family: 'Century Gothic';
    text-align: center;
    width: 100%;
    padding: 1vw;
    font-size: 1vw;
    cursor: pointer;
    transition: 0.05s ease;
    outline: 0.1vw solid rgba(128, 128, 128, 0.15);
}

.admin-element:hover {
    outline-color: rgba(128, 128, 128, 0.312);
}

.admin-element.selected {
    outline-color: var(--black);
}

#no-result-container {
    z-index: 1;
    position: absolute;
    height: 2vw;
    font-size: 1.3vw;
    align-items: center;
    justify-content: center;
    text-align: justify;
    width: 45%;
    left: 50%;
    top: 55%;
    display: flex;
    gap: 0.5vw;
    opacity: 0.6;
    transform: translate(-50%, -50%);
    font-family: 'Century Gothic';

}
</style>